import { acceptHMRUpdate, defineStore } from 'pinia'
import type { IMemberRoundUpRule } from '~/types/assetBuildingProgram.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IAutoDisburseMemberResponse, IDisburseRewardsRequestParams, ILogRewardsAsDistributedToMemberRequestParams, IMember, IMemberRequestParams } from '~/types/member.types'
import type { IPlatformResponse } from '~/types/platform.types'

export const useMembersStore = defineStore('members', () => {
  const oliveBaseUrl = '/members'

  // #region state
  const members = ref<IMember[]>([])
  const selectedMemberId = ref<string>()
  const memberIdsCurrentPage = ref<string[]>([])
  const numberOfMembers = ref<number | undefined>()
  const membersRoundUpRules = ref<IMemberRoundUpRule[]>([])

  const autoDisburseMemberResponse = ref<IAutoDisburseMemberResponse>()

  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IMemberRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getMemberById = computed(() => {
    return (id: string) => members.value.find(m => m.id === id)
  })

  const getMembersCurrentPage = computed (() => {
    return members.value.filter(m => memberIdsCurrentPage.value.includes(m.id))
  })

  const getMemberRoundUpRules = computed(() => {
    return (memberId: string) => membersRoundUpRules.value.filter(a => a.memberId === memberId)
  })

  const getSelectedMember = computed(() => {
    if (selectedMemberId.value)
      return getMemberById.value(selectedMemberId.value)
  })
  // #endregion

  // #region actions
  const loadMembers = async (params?: IMemberRequestParams) => {
    const {
      response,
      error: memberError,
      run: loadMembers,
    } = useOliveAPI<IPlatformResponse<IMember>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading members',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadMembers()

    if (response.value?.items && !memberError.value.hasError) {
      members.value = useArrayUnique([...response.value.items, ...members.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        memberIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfMembers.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = memberError.value }

    isLoading.value = false
  }

  const loadMember = async (memberId: string) => {
    selectedMemberId.value = memberId

    if (getMemberById.value(memberId))
      return

    const {
      response,
      error: memberError,
      run: loadMember,
    } = useOliveAPI<IMember>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${memberId}`,
      errorMessage: 'Error loading member',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadMember()

    if (response.value && !memberError.value.hasError)
      members.value = useArrayUnique([response.value, ...members.value], (a, b) => a.id === b.id).value
    else
      error.value = memberError.value

    isLoading.value = false
  }

  const loadMemberRoundUpRules = async (memberId: string) => {
    const {
      response,
      error: memberError,
      run: loadMemberRoundUpRules,
    } = useOliveAPI<IMemberRoundUpRule[]>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${memberId}/asset_building_program_round_up_rules`,
      errorMessage: 'Failed to get member round up rules',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadMemberRoundUpRules()

    if (response.value && !memberError.value.hasError) {
      membersRoundUpRules.value = response.value.map((m) => {
        return {
          memberId,
          assetBuildingProgramId: m.assetBuildingProgramId,
          priority: m.priority,
          roundUpRuleId: m.roundUpRuleId,
          roundUpRule: m.roundUpRule,
        }
      },
      )
    }
    else { error.value = memberError.value }

    isLoading.value = false
  }

  const addMember = async (member: IMember) => {
    const {
      response,
      error: memberError,
      run: addMember,
    } = useOliveAPI<IMember>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: member,
      successMessage: 'Member created successfully',
      errorMessage: 'Failed to create member',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addMember()

    if (response.value && !memberError.value.hasError) {
      members.value = useArrayUnique([response.value, ...members.value], (a, b) => a.id === b.id).value
      selectedMemberId.value = response.value.id
    }
    else { error.value = memberError.value }

    isLoading.value = false
  }

  const updateMember = async (member: IMember) => {
    selectedMemberId.value = member.id

    const {
      response,
      error: memberError,
      run: updateMember,
    } = useOliveAPI<IMember>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${member.id}`,
      data: member,
      successMessage: 'Member updated successfully',
      errorMessage: 'Failed to update member',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateMember()

    if (response.value && !memberError.value.hasError)
      members.value = useArrayUnique([response.value, ...members.value], (a, b) => a.id === b.id).value
    else
      error.value = memberError.value

    isLoading.value = false
  }

  const logRewardsAsDistributedToMember = async (id: string, requestParams: ILogRewardsAsDistributedToMemberRequestParams) => {
    const {
      run: logRewardsAsDistributedToMember,
    } = useOliveAPI<string[]>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${id}/log_rewards_as_distributed_to_member`,
      data: requestParams,
      successMessage: 'Member rewards marked as paid',
      errorMessage: 'Failed to mark Member rewards as paid',
    })
    isLoading.value = true
    error.value = getOliveError()

    await logRewardsAsDistributedToMember()

    isLoading.value = false
  }

  const payOutToMember = async (id: string, requestParams: IDisburseRewardsRequestParams) => {
    const {
      response,
      error: memberError,
      run: payOutToMember,
    } = useOliveAPI<IAutoDisburseMemberResponse>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${id}/disburse_rewards`,
      data: requestParams,
      errorMessage: 'Failed to payout to Member',
    })
    isLoading.value = true
    autoDisburseMemberResponse.value = undefined
    error.value = getOliveError()

    await payOutToMember()

    if (response.value && !memberError.value.hasError) {
      useCardTransactionsStore().clearMemberTransactions(id)
      autoDisburseMemberResponse.value = response.value
    }
    else {
      error.value = memberError.value
    }

    isLoading.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    memberIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearSelectedMember = () => {
    selectedMemberId.value = undefined
  }
  // #endregion

  return {
    members,
    numberOfMembers,
    getMemberById,
    getMembersCurrentPage,
    getMemberRoundUpRules,
    getSelectedMember,
    clearSelectedMember,
    isLoading,
    filter,
    error,
    loadMember,
    loadMembers,
    loadMemberRoundUpRules,
    addMember,
    updateMember,
    logRewardsAsDistributedToMember,
    payOutToMember,
    autoDisburseMemberResponse,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMembersStore as any, import.meta.hot))
