import type { RouteMeta } from 'vue-router'
import generatedRoutes from '~pages'
import { setupLayouts } from 'virtual:generated-layouts'
import { AuthenticationProperties as auth0, AuthenticationGuard, AuthenticationState } from 'vue-auth0-plugin'

import { pageview } from 'vue-gtag'
import { createRouter, createWebHistory } from 'vue-router'
import { SNACKBAR_TIMER } from '~/types/notification.types'
import { PERMISSION } from '~/types/permission.types'

export {}

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth: boolean
    layout: string
    requiresClientAccess?: boolean
    requiresAdminAccess?: boolean
    requiresCorporateAccess?: boolean
  }
}

enum UNRESOLVEDPAGES {
  NO_ACCESS = 'no-access',
  CALLBACK = 'callback',
  LOGOUT = 'logout',
  FORCELOGOUT = 'forcelogout',
  ERROR = 'error',
}

interface AuthenticationStateError {
  error?: {
    error: string
    error_description: string
  }
}

// generate routes
const routes = setupLayouts(generatedRoutes)

// guard routes
routes.map((route) => {
  if (route.children?.at(0)?.meta?.requiresAuth)
    route.beforeEnter = AuthenticationGuard
  return route
})

// add routes to router
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export function redirectToNoAccessPage() {
  router.replace({ name: 'no-access' })
}

export function redirectToErrorPage(title = 'Error', description = 'Something went wrong.') {
  router.replace({ name: 'error', query: { title, description } })
}

export function redirectToPageNotFound() {
  router.replace({ name: 'notfound' })
  setTimeout(() => {
    useNotifications().notification.visible = false
  }, SNACKBAR_TIMER)
}

async function userAccess(meta: RouteMeta, clientId: string, corporateId: string) {
  const {
    requiresAuth,
    requiresClientAccess,
    requiresAdminAccess,
    requiresCorporateAccess,
  } = meta
  const authenticated = AuthenticationState.authenticated
  const haveAccessToClient = useClientsStore().canAccessClient(clientId)
  const isAdmin = useUserStore().canAccessAllClients

  if (requiresAuth) {
    if (!authenticated) {
      auth0.loginWithRedirect()
      if (AuthenticationState.error)
        throw new Error(`Unable to authenticate user:${AuthenticationState.error}`)
    }
  }

  if (requiresClientAccess) {
    if (!haveAccessToClient)
      router.replace({ name: 'index' })

    if (haveAccessToClient)
      useContextStore().setClientId(clientId)
  }

  if (requiresAdminAccess) {
    if (!isAdmin)
      router.replace({ name: 'index' })

    if (isAdmin)
      useContextStore().setClientId(useClientsStore().adminClient.id)
  }

  if (requiresCorporateAccess) {
    try {
      await useCorporatesStore().loadCorporate(corporateId)
    }
    catch (e) {
      if (isAdmin)
        useContextStore().setClientId(useClientsStore().adminClient.id)

      router.replace({ name: 'index' })
    }
  }
}

router.beforeEach((to, from) => {
  if (from.name === UNRESOLVEDPAGES.CALLBACK) {
    const authenticationState = AuthenticationState as AuthenticationStateError
    if (authenticationState.error && authenticationState.error.error === 'access_denied')
      redirectToErrorPage('Access Denied', authenticationState.error?.error_description)
  }
})

router.beforeResolve(async (to) => {
  if (!Object.values(UNRESOLVEDPAGES).includes(to.name as UNRESOLVEDPAGES)) {
    await useUserStore().loadMyUser()
    const clientId = (to.params?.client as string)?.toLowerCase()
    const corporateId = (to.params?.corporate as string)?.toLowerCase()
    const user = useUserStore().me

    if (clientId)
      useContextStore().setClientId(clientId)
    else if (corporateId)
      useContextStore().setCorporateId(corporateId)

    if (useUserStore().checkUserPermission(PERMISSION.CAN_VIEW_CLIENTS))
      await useClientsStore().loadClients()

    if (user.id !== undefined) {
      if (user.corporates.length > 0) {
        await useCorporatesStore().loadCorporateByIds(user.corporates.map(c => c.id))

        const isAdmin = useUserStore().canAccessAllClients
        if (!isAdmin && user.clientIds.length === 0) {
          if (corporateId)
            useContextStore().setCorporateId(corporateId)
          else
            useContextStore().setCorporateId(user.corporates[0].id)
        }
      }
    }

    await userAccess(to.meta, clientId, corporateId)
  }

  if (to.name === UNRESOLVEDPAGES.LOGOUT || to.name === UNRESOLVEDPAGES.FORCELOGOUT)
    sessionStorage.clear()
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    pageview({
      page_title: to.name as string,
      page_path: to.fullPath,
    })
  }
})

router.onError((error, to) => {
  if (/Login required/.test(error.message))
    auth0.loginWithRedirect()

  if (/Failed to fetch dynamically imported module/.test(error.message)) {
    useNotifications().notifyInfo('New version detected.', 'Reloading application')
    setTimeout(() => {
      window.location.assign(to.fullPath)
    }, 3000)
  }
})
