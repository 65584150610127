<template>
  <v-list class="poweredby d-flex justify-center">
    <p class="subtitle-1 mb-0">
      Powered by
    </p>
    <v-img
      class="ml-1 mb-1 desaturate"
      src="/logo.png"
      contain
      max-width="50"
      alt="Olive"
    />
  </v-list>
</template>

<style lang="scss" scoped>
.poweredby {
  p {
    color: grey;
  }
  .desaturate {
    filter: saturate(0);
  }
}
</style>
