<script setup lang="ts">
import { onBeforeRouteLeave } from 'vue-router'
import { useTheme } from 'vuetify'
import { NOTIFICATION_ICON_TYPE, NOTIFICATION_TYPE } from '~/types/notification.types'

const theme = useTheme()
const notification = useNotifications().notification

function snackbar() {
  const {
    icon = NOTIFICATION_ICON_TYPE.SUCCESS,
  } = notification

  let textColor = theme.global.current.value.colors.success
  switch (notification.type) {
    case NOTIFICATION_TYPE.SUCCESS: {
      textColor = theme.global.current.value.colors.success
      break
    }
    case NOTIFICATION_TYPE.ERROR: {
      textColor = theme.global.current.value.colors.error
      break
    }
    case NOTIFICATION_TYPE.WARNING: {
      textColor = theme.global.current.value.colors.warning
      break
    }
    case NOTIFICATION_TYPE.INFO: {
      textColor = theme.global.current.value.colors.info
      break
    }
  }

  return {
    icon,
    textColor,
  }
}

onBeforeRouteLeave(() => {
  notification.visible = false
})
</script>

<template>
  <v-snackbar
    v-model="notification.visible"
    :color="theme.global.current.value.variables?.notificationBgColor as string || 'white'"
    :timeout="notification.timeout"
    location="top right"
    variant="elevated"
    rounded="true"
    class="notification"
  >
    <template #actions>
      <v-btn
        color="primary"
        variant="plain"
        @click="notification.visible = false"
      >
        Close
      </v-btn>
    </template>
    <v-layout
      align-center
      pr-4
    >
      <v-icon
        class="pr-1"
        :style="{ color: snackbar().textColor }"
      >
        {{ snackbar().icon }}
      </v-icon>
      <v-layout
        column
        class="pl-1 d-block"
      >
        <div
          class="v-snack__text"
          :style="{ color: snackbar().textColor }"
        >
          {{ notification.title }}
        </div>
        <div
          v-if="notification.text"
          class="v-snack__text__detail"
          :style="{ color: snackbar().textColor }"
        >
          {{ notification.text }}
        </div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<style lang="scss" scoped>
.v-snack__text {
  font-size: 16px !important;
  line-height: 150%;
}

.v-snack__text__detail {
  font-size: 14px !important;
  line-height: 150%;
}

.notification {
  top: 60px;
}
</style>
