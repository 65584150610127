<template>
  <TopBar />
  <NavBar />
  <AppNotifications />
  <v-main>
    <v-container fluid>
      <RouterView v-slot="{ Component, route }">
        <Transition
          name="default"
          appear
          mode="out-in"
        >
          <div :key="route.path">
            <component :is="Component" />
          </div>
        </Transition>
      </RouterView>
    </v-container>
  </v-main>
</template>
