<script setup lang="ts">
const props = withDefaults(defineProps<{
  hidden?: boolean
}>(), {
  hidden: false,
})

const nav = useNavigationStore()
nav.navBar.isHidden = props.hidden
</script>

<template>
  <template v-if="!nav.navBar.isHidden">
    <v-navigation-drawer
      v-model="nav.navBar.isOpen"
      app
      width="250"
      color="grey98"
      border="none"
    >
      <NavMenu v-once />
      <template #append>
        <NavFooter v-once />
      </template>
    </v-navigation-drawer>
  </template>
</template>

<style lang="scss" scoped>
</style>
