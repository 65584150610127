import type { IBrandRequestParams } from './brand.types'
import type { DAYS_AVAILABILITY, OliveRequestParams, SUPPORTED_LOCALE } from './common.types'
import type { OFFER_MARKETING_FEE_TYPE } from './marketing.types'
import type { INTERVAL_TYPE, IPaginationOptions, ISearchOptions } from './platform.types'
import type { IStore } from './store.types'

// #region enums
export enum OFFER_STATE {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  PAUSED = 'paused',
  ARCHIVED = 'archived',
}

export enum OFFER_REDEMPTION_TYPE {
  ALL = 'all', // NOTE: Will be removed one day from the backend
  CARDLINKED = 'cardlinked',
  CLICK = 'click',
  MOBILE = 'mobile',
  CLICK_SSO = 'click_sso',
}

export enum OFFER_REDEMPTION_TRIGGER {
  MIN_PURCHASE_AMT = 'minimum_purchase_amount',
  CUMULATIVE_PURCHASE_AMT = 'cumulative_purchase_amount',
  PURCHASE_FREQUENCY = 'purchase_frequency',
  MOBILE = 'mobile',
  CLICK = 'click',
  CLICK_SSO = 'click_sso',
}

export enum OFFER_REACH {
  STATE = 'state',
  NAT = 'national',
  ONLINE = 'online_only',
}

export enum OFFER_REWARD_TYPE {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export enum PAYMENT_METHOD {
  INVOICE = 'invoice',
  CREDIT_CARD = 'credit_card',
}

export enum OFFER_EDIT_WIZARD_COMPONENT_STATE {
  EDIT = 'edit',
  DISABLED = 'disabled',
  INCREASE_ONLY = 'increase_only',
}

export enum OFFER_EDIT_WIZARD_COMPONENTS {
  DATE_PICKER = 'datePicker',
  DAYS_AVAILABILITY = 'daysAvailability',
  REACH = 'reach',
  REDEMPTION_TYPE = 'redemptionType',
  REDEMPTION_INSTRUCTIONS_URL = 'redemptionInstructionsUrl',
  REDEMPTION_TRIGGER = 'redemptionTrigger',
  PURCHASE_AMOUNT = 'purchaseAmount',
  PURCHASE_FREQUENCY = 'purchaseFrequency',
  REDEEM_LIMIT_PER_USER = 'redeemLimitPerUser',
  REDEEM_LIMIT_PER_USER_INTERVAL = 'redeemLimitPerUserInterval',
  REDEEM_LIMIT_PER_USER_INTERVAL_COUNT = 'redeemLimitPerUserIntervalCount',
  REDEEM_LIMIT_WINDOW_TOGGLE = 'redeemLimitWindowToggle',
  TOTAL_REDEEM_LIMIT = 'totalRedeemLimit',
  REWARD_TYPE = 'rewardType',
  REWARD_VALUE = 'rewardValue',
  REWARD_MAX_VALUE = 'rewardMaxValue',
  TITLE = 'title',
  TILE = 'tile',
  DESCRIPTION = 'description',
  QUALIFIER = 'qualifier',
  BUDGET = 'budget',
  BRAND = 'brand',
  STORES = 'stores',
}

export enum OFFER_REWARD_STATUS {
  TRIGGER_ACCUMULATING = 'trigger_accumulating',
  EARNED_PENDING_SETTLEMENT = 'earned_pending_settlement',
  TRIGGER_ACCUMULATING_EXPIRED = 'trigger_accumulating_expired',
  REJECTED = 'rejected',
  PENDING_REVIEW = 'pending_review',
  PENDING_MERCHANT_APPROVAL = 'pending_merchant_approval',
  PENDING_MERCHANT_FUNDING = 'pending_merchant_funding',
  PENDING_TRANSFER_TO_PUBLISHER = 'pending_transfer_to_publisher',
  DISTRIBUTED_TO_PUBLISHER = 'distributed_to_publisher',
  DISTRIBUTED_TO_MEMBER_DISTRIBUTOR = 'distributed_to_member_distributor',
  DISTRIBUTION_TO_MEMBER_FAILED = 'distribution_to_member_failed',
  DISTRIBUTED_TO_OLIVE = 'distributed_to_olive',
}

export enum OFFER_AVAILABILITY {
  GLOBAL = 'global',
  CLIENT_ONLY = 'client_only',
  ALL = 'all',
}

export enum OFFER_VISIBILITY {
  VISIBLE = 'visible',
  NOT_VISIBLE = 'not_visible',
  ALL = 'all',
}

export enum OFFER_PAGE_LAYOUT {
  LIST = 'list',
  GRID = 'grid',
  GRID_SUMMARY = 'grid_summary',
  MAP = 'map',
}

export enum OFFER_PREVIEW_TYPE {
  COMPACT = 'compact',
  DEFAULT = 'default',
}

export enum OFFER_SSO_DESTINATION {
  TRAVELER_CASHBACK = 'traveler_cashback',
}
// #endregion

// #region interfaces
export interface IOffer {
  id: string
  brandDba: string
  brandLogoXs?: string
  brandLogoSm?: string
  brandLogoMd?: string
  brandStubCopy: string
  title: string
  startDate: string
  endDate: string
  offerState: OFFER_STATE
  storeDetails: IStore[]
  stores: string[]
  redemptionType: OFFER_REDEMPTION_TYPE
  redemptionInstructionUrl?: string
  clientFullName?: string
  created?: string
  redemptionTrigger: OFFER_REDEMPTION_TRIGGER
  purchaseAmount: number
  purchaseFrequency: number
  redeemLimitPerUser?: number
  totalRedeemLimit?: number
  budget?: number
  reward: IReward
  description: string
  reach: OFFER_REACH
  qualifier: string
  tile: string
  currency: string
  daysAvailability: DAYS_AVAILABILITY[]
  brandId: string | null
  brandWebsite?: string
  brandBanner?: string
  availability?: OFFER_AVAILABILITY
  corporateId?: string
  locale?: SUPPORTED_LOCALE
  marketingFeeType: OFFER_MARKETING_FEE_TYPE
  marketingFeeValue: number
  redeemLimitPerUserInterval?: INTERVAL_TYPE
  redeemLimitPerUserIntervalCount?: number
  extOfferId?: string
  visitPayload?: string
  offerSSODestination?: OFFER_SSO_DESTINATION
}

export interface IOfferRequestParams extends OliveRequestParams, IPaginationOptions, IBrandRequestParams, ISearchOptions {
  offerStates?: OFFER_STATE[]
  redemptionType?: OFFER_REDEMPTION_TYPE[]
  loyaltyProgramId?: string
  offerId?: string[]
  currencyCode?: string[]
  radiusLatitude?: number
  radiusLongitude?: number
  radius?: number
  availability?: OFFER_AVAILABILITY | OFFER_AVAILABILITY[]
  brandParentCategory?: string[]
  brandFirstCategory?: string | string[]
  brandSecondCategory?: string | string[]
  state?: string | string[]
  city?: string | string[]
  locale?: SUPPORTED_LOCALE | SUPPORTED_LOCALE[]
  startDate?: string | string[]
  endDate?: string | string[]
  rewardType?: OFFER_REWARD_TYPE[]
  title?: string
  search?: string
  searchStores?: string
  brandTag?: string | string[]
  clientTag?: string | string[]
  clientTagClientId?: string
  countryCode?: string
  visitPayload?: string
  offerSSODestination?: OFFER_SSO_DESTINATION
}

export interface IReward {
  type: OFFER_REWARD_TYPE
  value: number
  maxValue?: number
}

export interface IOfferBudgetAndPayments {
  budgetAmount?: number
  agreeToTerms: boolean
  paymentMethod: PAYMENT_METHOD
  marketingFeeType: OFFER_MARKETING_FEE_TYPE
  marketingFeeValue: number
}

export interface IOfferRedemption {
  id: string
  date: string
  cardId: string
  memberId: string
  brandId: string
  storeId: string
  offerId: string
  cardLast4Digits: string
  mcc: string
  amount: number
  rewardAmount: number
  clientId: string
}

export interface ITotalRewardsStatus {
  rewardStatus: OFFER_REWARD_STATUS
  count: number
  rewardAmount: number
  marketingFeeAmount: number
  amount: number
  distributedToMemberAmount: number
  offerId?: string
}
// #endregion
