export class OliveError extends Error {
  errorCode: number

  constructor(message: string, errorCode: number) {
    super(message)
    this.errorCode = errorCode

    Object.setPrototypeOf(this, OliveError.prototype)
  }
}
