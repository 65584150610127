import type { INotification } from '~/types/notification.types'
import { NOTIFICATION_ICON_TYPE, NOTIFICATION_TYPE, SNACKBAR_TIMER } from '~/types/notification.types'

const notification: INotification = reactive({})

function showSnackbar(title: string, text?: string, icon?: NOTIFICATION_ICON_TYPE, timeout?: number, type?: NOTIFICATION_TYPE): void {
  setTimeout(() => {
    notification.title = title
    notification.text = text
    notification.icon = icon
    notification.timeout = timeout
    notification.type = type
    notification.visible = true
  }, SNACKBAR_TIMER)
}

function notifyError(title?: string, text?: string): void {
  showSnackbar(title || 'Error', text || '', NOTIFICATION_ICON_TYPE.ERROR, 4000, NOTIFICATION_TYPE.ERROR)
}
function notifyInfo(title: string, text?: string): void {
  showSnackbar(title, text, NOTIFICATION_ICON_TYPE.INFO, 4000, NOTIFICATION_TYPE.INFO)
}
function notifyWarning(title: string, text?: string): void {
  showSnackbar(title, text, NOTIFICATION_ICON_TYPE.WARNING, 4000, NOTIFICATION_TYPE.WARNING)
}
function notifySuccess(title: string, text?: string): void {
  showSnackbar(title, text, NOTIFICATION_ICON_TYPE.SUCCESS, 4000, NOTIFICATION_TYPE.SUCCESS)
}

export default function useNotifications() {
  return {
    notification,
    notifyError,
    notifyInfo,
    notifyWarning,
    notifySuccess,
  }
}
