import { acceptHMRUpdate, defineStore } from 'pinia'
import type { IAssetBuildingProgram, IAssetBuildingProgramMember, IAssetBuildingProgramRequestParams, IAssetBuildingProgramRoundUpRule } from '~/types/assetBuildingProgram.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'

export const useAssetBuildingProgramsStore = defineStore('assetBuildingPrograms', () => {
  const oliveBaseUrl = '/asset_building_programs'

  // #region state
  const assetBuildingPrograms = ref<IAssetBuildingProgram[]>([])
  const assetBuildingProgramRoundUpRules = ref<IAssetBuildingProgramRoundUpRule[]>([])
  const assetBuildingProgramMembers = ref<IAssetBuildingProgramMember[]>([])
  const assetBuildingProgramIdsCurrentPage = ref<string[]>([])
  const numberOfAssetBuildingPrograms = ref<number | undefined>()

  const selectedAssetBuildingProgramId = ref<string>()

  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const isLoadingForRoundUps = ref(false)
  const isLoadingForMembers = ref(false)

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getAssetBuildingProgramById = computed(() => {
    return (id: string) => assetBuildingPrograms.value.find(a => a.id === id)
  })

  const getAssetBuildingProgramRoundUpRulesSorted = computed(() => {
    return (assetBuildingProgramId: string) => assetBuildingProgramRoundUpRules.value.filter(a => a.assetBuildingProgramId === assetBuildingProgramId).sort((a, b) => b.priority - a.priority)
  })

  const getAssetBuildingProgramRoundUpRulesById = computed(() => {
    return (assetBuildingProgramId: string, roundUpRuleId: string) => assetBuildingProgramRoundUpRules.value.find(a => a.assetBuildingProgramId === assetBuildingProgramId && a.roundUpRuleId === roundUpRuleId)
  })

  const getAssetBuildingProgramMemberByMemberId = computed(() => {
    return (memberId: string) => assetBuildingProgramMembers.value.find(a => a.memberId === memberId)
  })

  const getAssetBuildingProgramsCurrentPage = computed(() => {
    return assetBuildingPrograms.value.filter(a => assetBuildingProgramIdsCurrentPage.value.includes(a.id))
  })

  const getActiveAssetBuildingProgramByClientId = computed(() => {
    return (clientId: string) => assetBuildingPrograms.value.filter(a => a.active === true && a.clientId === clientId)
  })

  const getSelectedAssetBuildingProgram = computed(() => {
    if (selectedAssetBuildingProgramId.value)
      return getAssetBuildingProgramById.value(selectedAssetBuildingProgramId.value)
  })
  // #endregion

  // #region AssetBuildingPrograms actions
  const loadAssetBuildingPrograms = async (params?: IAssetBuildingProgramRequestParams) => {
    const {
      response,
      error: assetBuildingProgramError,
      run: loadBrands,
    } = useOliveAPI<IPlatformResponse<IAssetBuildingProgram>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading brands',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadBrands()

    if (response.value?.items && !assetBuildingProgramError.value.hasError) {
      assetBuildingPrograms.value = useArrayUnique([...response.value.items, ...assetBuildingPrograms.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        assetBuildingProgramIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfAssetBuildingPrograms.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = assetBuildingProgramError.value }

    isLoading.value = false
  }

  const loadAssetBuildingProgram = async (id: string) => {
    selectedAssetBuildingProgramId.value = id
    if (getAssetBuildingProgramById.value(id))
      return

    const {
      response,
      error: assetBuildingProgramError,
      run: loadAssetBuildingProgram,
    } = useOliveAPI<IAssetBuildingProgram>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading asset building program',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadAssetBuildingProgram()

    if (response.value && !assetBuildingProgramError.value.hasError)
      assetBuildingPrograms.value = useArrayUnique([response.value, ...assetBuildingPrograms.value], (a, b) => a.id === b.id).value
    else error.value = assetBuildingProgramError.value

    isLoading.value = false
  }

  const addAssetBuildingProgram = async (lp: IAssetBuildingProgram) => {
    const {
      response,
      error: assetBuildingProgramError,
      run: addAssetBuildingProgram,
    } = useOliveAPI<IAssetBuildingProgram>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: lp,
      successMessage: 'Asset Building Program created',
      errorMessage: 'Failed to create Asset Building Program',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addAssetBuildingProgram()

    if (response.value && !assetBuildingProgramError.value.hasError) {
      assetBuildingPrograms.value = useArrayUnique([response.value, ...assetBuildingPrograms.value], (a, b) => a.id === b.id).value
      selectedAssetBuildingProgramId.value = response.value.id
    }
    else { error.value = assetBuildingProgramError.value }

    isLoading.value = false
  }

  const updateAssetBuildingProgram = async (id: string, params: IAssetBuildingProgramRequestParams) => {
    selectedAssetBuildingProgramId.value = id

    const {
      response,
      error: assetBuildingProgramError,
      run: updateAssetBuildingProgram,
    } = useOliveAPI<IAssetBuildingProgram>({
      method: HTTP_METHOD.PUT,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/${id}`),
      data: params,
      successMessage: 'Asset Building Program updated',
      errorMessage: 'Failed to save changes to the Asset Building Program',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateAssetBuildingProgram()

    if (response.value && !assetBuildingProgramError.value.hasError)
      assetBuildingPrograms.value = useArrayUnique([response.value, ...assetBuildingPrograms.value], (a, b) => a.id === b.id).value
    else
      error.value = assetBuildingProgramError.value

    isLoading.value = false
  }
  // #endregion

  // #region AssetBuildingProgramRoundUpRules actions
  const loadAssetBuildingProgramRoundUpRules = async (assetBuildingProgramId: string) => {
    const {
      response,
      error: assetBuildingProgramError,
      run: loadAssetBuildingProgramRoundUpRules,
    } = useOliveAPI<IAssetBuildingProgramRoundUpRule[]>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${assetBuildingProgramId}/asset_building_program_round_up_rules`,
      errorMessage: 'Failed to get asset building program round up rules',
    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await loadAssetBuildingProgramRoundUpRules()

    if (response.value && !assetBuildingProgramError.value.hasError)
      assetBuildingProgramRoundUpRules.value = response.value
    else
      error.value = assetBuildingProgramError.value

    isLoadingForRoundUps.value = false
  }

  const addAssetBuildingProgramRoundUpRule = async (assetBuildingProgram: IAssetBuildingProgramRoundUpRule) => {
    const {
      error: assetBuildingProgramError,
      run: addAssetBuildingProgramRoundUpRule,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${assetBuildingProgram.assetBuildingProgramId}/asset_building_program_round_up_rules`,
      data: assetBuildingProgram,
      successMessage: 'Successfully added round up rule to rounding program',
      errorMessage: 'Error adding round up rule',

    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await addAssetBuildingProgramRoundUpRule()

    if (assetBuildingProgramError.value.hasError)
      error.value = assetBuildingProgramError.value

    isLoadingForRoundUps.value = false
  }

  const modifyAssetBuildingProgramRoundUpRules = async (assetBuldingProgramId: string, abprr: IAssetBuildingProgramRoundUpRule[]) => {
    const {
      response,
      error: assetBuildingProgramError,
      run: modifyAssetBuildingProgramRoundUpRules,
    } = useOliveAPI<IAssetBuildingProgramRoundUpRule[]>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${assetBuldingProgramId}/asset_building_program_round_up_rules`,
      data: abprr,
      successMessage: 'Successfully changed priority',
      errorMessage: 'Failed to change priority',
    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await modifyAssetBuildingProgramRoundUpRules()

    if (response.value && !assetBuildingProgramError.value.hasError)
      assetBuildingProgramRoundUpRules.value = useArrayUnique([...response.value, ...assetBuildingProgramRoundUpRules.value], (a, b) => a.assetBuildingProgramId === b.assetBuildingProgramId && a.roundUpRuleId === b.roundUpRuleId).value
    else
      error.value = assetBuildingProgramError.value

    isLoadingForRoundUps.value = false
  }

  const deleteAssetBuildingProgramRoundUpRule = async (assetBuildingProgramId: string, roundUpRuleId: string) => {
    const {
      error: assetBuildingProgramError,
      run: deleteAssetBuildingProgramRoundUpRule,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${assetBuildingProgramId}/asset_building_program_round_up_rules/${roundUpRuleId}`,
      successMessage: 'Successfully removed round up rule from program',
      errorMessage: 'Failed to remove round up rule from program',
    })
    isLoadingForRoundUps.value = true
    error.value = getOliveError()

    await deleteAssetBuildingProgramRoundUpRule()

    if (!assetBuildingProgramError.value.hasError) {
      const abprr = getAssetBuildingProgramRoundUpRulesById.value(assetBuildingProgramId, roundUpRuleId)
      if (abprr) {
        // TODO: change filter logic to make more sense even though concatenating the ID's work
        assetBuildingProgramRoundUpRules.value = assetBuildingProgramRoundUpRules.value.filter(l => (l.assetBuildingProgramId + l.roundUpRuleId !== assetBuildingProgramId + roundUpRuleId))
      }
    }
    else { error.value = assetBuildingProgramError.value }

    isLoadingForRoundUps.value = false
  }
  // #endregion

  // #region AssetBuildingProgramMembers actions
  const loadAssetBuildingProgramMembers = async (id: string) => {
    const {
      response,
      error: assetBuildingProgramError,
      run: loadAssetBuildingProgramMembers,
    } = useOliveAPI<IAssetBuildingProgramMember[]>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}/asset_building_program_members`,
      errorMessage: 'Failed to get asset building program members',
    })
    isLoadingForMembers.value = true
    error.value = getOliveError()

    await loadAssetBuildingProgramMembers()

    if (response.value && !assetBuildingProgramError.value.hasError)
      assetBuildingProgramMembers.value = response.value
    else
      error.value = assetBuildingProgramError.value

    isLoadingForMembers.value = false
  }

  const addAssetBuildingProgramMembers = async (id: string, lpm: IAssetBuildingProgramMember[]) => {
    const {
      response,
      error: assetBuildingProgramError,
      run: addAssetBuildingProgramMembers,
    } = useOliveAPI<IAssetBuildingProgramMember[]>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${id}/asset_building_program_members`,
      data: lpm,
    })
    isLoadingForMembers.value = true
    error.value = getOliveError()

    await addAssetBuildingProgramMembers()

    if (response.value && !assetBuildingProgramError.value.hasError)
      assetBuildingProgramMembers.value = useArrayUnique([...response.value, ...assetBuildingProgramMembers.value], (a, b) => a.memberId === b.memberId).value
    else
      error.value = assetBuildingProgramError.value

    isLoadingForMembers.value = false
  }

  const addAssetBuildingProgramMember = async (id: string, lpm: IAssetBuildingProgramMember) => {
    const {
      error: assetBuildingProgramError,
      run: addAssetBuildingProgramMember,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${id}/asset_building_program_members`,
      data: lpm,
      successMessage: 'Member added to asset building program',
      errorMessage: 'Failed to add member to asset building program',
    })
    isLoadingForMembers.value = true
    error.value = getOliveError()

    await addAssetBuildingProgramMember()

    if (assetBuildingProgramError.value.hasError)
      error.value = assetBuildingProgramError.value

    isLoadingForMembers.value = false
  }

  const deleteAssetBuildingProgramMember = async (id: string, memberId: string) => {
    const {
      error: assetBuildingProgramError,
      run: deleteAssetBuildingProgramMember,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${id}/asset_building_program_members/${memberId}`,
      successMessage: 'Member removed from asset building program',
      errorMessage: 'Failed to remove member from asset building program',
    })
    isLoadingForMembers.value = true
    error.value = getOliveError()

    await deleteAssetBuildingProgramMember()

    if (!assetBuildingProgramError.value.hasError) {
      const lpm = getAssetBuildingProgramMemberByMemberId.value(memberId)
      if (lpm)
        assetBuildingProgramMembers.value = assetBuildingProgramMembers.value.filter(l => l.memberId !== memberId)
    }
    else { error.value = assetBuildingProgramError.value }

    isLoadingForMembers.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    assetBuildingProgramIdsCurrentPage.value = []
  }

  const clearSelectedAssetBuildingProgram = () => {
    selectedAssetBuildingProgramId.value = undefined
  }

  // #endregion

  return {
    assetBuildingPrograms,
    assetBuildingProgramRoundUpRules,
    assetBuildingProgramMembers,
    numberOfAssetBuildingPrograms,
    isLoading,
    isLoadingForRoundUps,
    isLoadingForMembers,
    getAssetBuildingProgramById,
    getAssetBuildingProgramsCurrentPage,
    getAssetBuildingProgramRoundUpRulesSorted,
    getActiveAssetBuildingProgramByClientId,
    getSelectedAssetBuildingProgram,
    clearSelectedAssetBuildingProgram,
    error,
    loadAssetBuildingProgram,
    loadAssetBuildingPrograms,
    addAssetBuildingProgram,
    updateAssetBuildingProgram,
    loadAssetBuildingProgramRoundUpRules,
    addAssetBuildingProgramRoundUpRule,
    modifyAssetBuildingProgramRoundUpRules,
    deleteAssetBuildingProgramRoundUpRule,
    loadAssetBuildingProgramMembers,
    addAssetBuildingProgramMembers,
    addAssetBuildingProgramMember,
    deleteAssetBuildingProgramMember,
    clearCurrentPage,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAssetBuildingProgramsStore as any, import.meta.hot))
