<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { IClientSelector } from '~/types/client.types'

const { showClientsList = false, showCorporatesList = false } = defineProps<{
  showClientsList?: boolean
  showCorporatesList?: boolean
}>()

const emit = defineEmits<{
  (e: 'onSelected', selected: boolean): void

}>()

const clientsStore = useClientsStore()
const corporateStore = useCorporatesStore()
const contextStore = useContextStore()
const userStore = useUserStore()

const { canAccessAllClients } = storeToRefs(userStore)

const LIST_WIDTH = 160

const clients = ref<IClientSelector[] | undefined>([])
const adminClient = ref<IClientSelector>(
  {
    id: clientsStore.adminClient.id,
    name: clientsStore.adminClient.fullName,
    isActive: false,
  },
)
const corporates = ref<IClientSelector[]>([])

function populateClients(): void {
  clients.value = clientsStore.clients?.map(
    ({ id, fullName }) => {
      return {
        id,
        name: fullName,
        isActive: id === contextStore.selectedClientId,
      }
    },
  )

  adminClient.value.isActive = contextStore.selectedClientId === clientsStore.adminClient.id
}

async function populateCorporates() {
  if (userStore.me?.corporates && userStore.me.corporates.length > 0) {
    const explicitCorporates = corporateStore.getCorporatesByIds(userStore.me?.corporates.map(c => c.id))
    corporates.value = explicitCorporates.map(
      ({ id, companyName }) => {
        return {
          id,
          name: companyName,
          isActive: id === contextStore.selectedCorporateId,
        }
      },
    )
  }
}

if (showClientsList)
  populateClients()
if (showCorporatesList)
  populateCorporates()

const searchString = ref<string>()
const clientsFiltered = computed(() => {
  if (searchString.value)
    return clients.value?.filter(f => f.name.toLowerCase().includes((searchString.value as string).toLowerCase()))
  return clients.value
})
const corporatesFiltered = computed(() => {
  if (searchString.value)
    return corporates.value?.filter(f => f.name.toLowerCase().includes((searchString.value as string).toLowerCase()))
  return corporates.value
})

function clearStoresCurrentPage() {
  useAccountTransactionsStore().clearCurrentPage()
  useAccountStatementsStore().clearCurrentPage()
  useAccountsStore().clearCurrentPage()
  useAssetBuildingProgramsStore().clearCurrentPage()
  useBrandsStore().clearCurrentPage()
  useClientCardTransactionsStore().clearCurrentPage()
  useCardTransactionsStore().clearCurrentPage()
  useCardsStore().clearCurrentPage()
  useCorporatesStore().clearCurrentPage()
  useOffersStore().clearCurrentPage()
  useOffersStore().clearFilter()
  useLoyaltyProgramsStore().clearCurrentPage()
  useMembersStore().clearCurrentPage()
  useRoundUpRulesStore().clearCurrentPage()
  useStoresStore().clearCurrentPage()
  useUserStore().clearCurrentPage()
  useWebhookStore().clearCurrentPage()
}

watch(() => [
  contextStore.selectedClientId,
  contextStore.selectedCorporateId,
], () => {
  clearStoresCurrentPage()
  populateClients()
  populateCorporates()
})

function selectClientItem(clientId: string) {
  contextStore.setClientId(clientId)
  emit('onSelected', true)
}

function selectCorporateItem(corporateId: string) {
  contextStore.setCorporateId(corporateId)
  emit('onSelected', true)
}

const listMinWidth = ref(LIST_WIDTH)
function setListMinWidth() {
  const vList = document.getElementById('client-select') as HTMLInputElement
  setTimeout(() => {
    listMinWidth.value = vList.offsetWidth
  }, 100)
}

function setBehaviour() {
  setFocusOnElementById('client-selector-search')
  setListMinWidth()
}

onMounted(() => {
  setBehaviour()
})
</script>

<template>
  <v-list
    id="client-select"
    density="compact"
    class="list"
    :min-width="listMinWidth"
  >
    <v-list-item v-if="clients && clients.length > 5">
      <v-text-field
        id="client-selector-search"
        v-model="searchString"
        label="Search"
        class="mb-2"
        append-inner-icon="mdi-magnify"
        variant="underlined"
        hide-details
        clearable
        autofocus
        autocomplete="one-time-code"
      />
    </v-list-item>
    <v-list-item
      v-if="canAccessAllClients && !searchString"
      :key="adminClient.id"
      :value="adminClient.id"
      :title="adminClient.name"
      :active="adminClient.isActive"
      :to="{
        name: 'clients',
      }"
      color="huntergreen"
      class="font-italic"
      @click="selectClientItem(adminClient.id)"
    />
    <v-divider v-if="canAccessAllClients" />
    <v-list-subheader
      v-if="clients && clients.length > 0"
    >
      <span class="text-uppercase font-weight-bold">Clients</span> <span class="text-caption">({{ clientsFiltered?.length }})</span>
    </v-list-subheader>
    <v-divider />
    <v-list-item
      v-for="client in clientsFiltered"
      :key="client.id"
      :value="client.id"
      :title="client.name"
      :active="client.isActive"
      :to="{
        name: 'client-overview',
        params: { client: client.id },
      }"
      color="huntergreen"
      @click="selectClientItem(client.id)"
    />
    <v-list-item
      v-if="searchString && clients && clients.length > 0 && clientsFiltered && clientsFiltered.length === 0"
      subtitle="No clients found"
    />
    <v-divider v-if="clients && clients.length > 0 && corporatesFiltered && corporatesFiltered.length > 0" />
    <v-list-subheader
      v-if="corporates.length > 0"
      class="text-uppercase font-weight-bold"
    >
      <span class="text-uppercase font-weight-bold">Corporates</span> <span class="text-caption">({{ corporatesFiltered?.length }})</span>
    </v-list-subheader>
    <v-divider v-if="corporates && corporates.length > 0" />
    <v-list-item
      v-for="corporate in corporatesFiltered"
      :key="corporate.id"
      :value="corporate.id"
      :title="corporate.name"
      :active="corporate.isActive"
      :to="{
        name: 'corporate-overview',
        params: { corporate: corporate.id },
      }"
      color="huntergreen"
      @click="selectCorporateItem(corporate.id)"
    />
    <v-list-item
      v-if="searchString && corporates && corporates.length > 0 && corporatesFiltered && corporatesFiltered.length === 0"
      subtitle="No corporates found"
    />
  </v-list>
</template>

<style lang="scss" scoped>
  .list {
    .v-list-subheader {
      font-size: 0.7em;
    }
  }
</style>
