import type { CONTRIBUTION_TYPE, SORT_ORDER } from './common.types'

// #region enums
export enum INTERVAL_TYPE {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}
// #endregion

// #region interfaces
export interface IPlatformResponse<T> {
  totalNumberOfRecords: number
  totalNumberOfPages: number
  items: T[]
}

export interface IPaginationOptions {
  pageSize?: number
  pageNumber?: number
  order?: SORT_ORDER
  sort?: string
}

export interface ISortOptions {
  key?: string
  order?: SORT_ORDER
}

export interface ISearchOptions {
  value?: string
  searchBy?: string
  clientId?: string
  includeGlobal?: boolean
}

export interface ICountOptions {
  clientId?: string
  rangeStartDate?: string
  rangeEndDate?: string
  isActive?: boolean
  memberId?: string
}

export interface IContributionByCategoryOptions {
  clientId: string
  rangeStartDate: string
  rangeEndDate: string
  type?: CONTRIBUTION_TYPE
  mcc?: string
  brandId?: string
  storeId?: string
  sort?: string
  top?: number
}

export interface IStoreOptions {
  storeId?: string[]
  corporateId?: string | string[]
  isOnline?: boolean
}

export interface ICorporateOptions {
  corporateId?: string | string[]
  companyName?: string
}

export interface IBrandOptions {
  brandId?: string[]
}
// #endregion
