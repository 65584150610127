import type { Ref } from 'vue'
import type { OliveRequestParams } from '~/types/common.types'

/**
 * Returns a properly formatted URL with query params, strips out properties which are null or undefined
 * @param url Base endpoint url
 * @param params Request params (OliveRequestParams)
 * @returns ComputedRef<string>
 */
export function useOliveURLRequestBuilder(url: string, params?: Ref<OliveRequestParams> | OliveRequestParams) {
  return computed(() => {
    if (!params)
      return url

    const search = new URLSearchParams()
    const obj = unref(params)
    const entries = Object.entries(obj)
      .filter(e => e[1] !== null && e[1] !== undefined)
      .filter(e => typeof e[1] == 'string' ? e[1].length > 0 : true)
      .sort(([a], [b]) => a > b ? 1 : a < b ? -1 : 0)
    entries.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          if (v !== null && v !== undefined)
            search.append(key, v as string)
        })
      }
      else { search.append(key, value!.toString()) }
    })
    return `${url}?${search.toString()}`
  })
}
