import type { IFormItem } from './common.types'

// #region enums
export enum LOGO_SIZE {
  EXTRA_SMALL = 'extra_small',
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum LOGO_SIZE_SHORT {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  BANNER = 'banner',
}
// #endregion

// #region interfaces
export interface ILogoBlob {
  logoBlob: File[] | undefined
  logoUrl: string
  logoSizeShort: LOGO_SIZE_SHORT
}

export interface ILogoFormItems {
  logoXs: IFormItem
  logoSm: IFormItem
  logoMd: IFormItem
  banner: IFormItem
}
// #endregion
