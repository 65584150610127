import type { IWebConfig } from './types/config.types'

const webConfig: IWebConfig = {
  auth: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
    redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URI,
    logout_uri: import.meta.env.VITE_AUTH0_LOGOUT_URI,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    cache_location: 'localstorage',
    useRefreshTokens: true,
  },
  api: {
    platform_api_url: import.meta.env.VITE_PLATFORM_API_URL,
    auth0_api_url: import.meta.env.VITE_AUTH0_API_URL,
  },
  app: {
    envName: import.meta.env.VITE_ENV_NAME ?? 'local',
    production: import.meta.env.VITE_ENV_NAME === 'production',
    gaMeasurementId: import.meta.env.VITE_GA_MEASUREMENT_ID,
    globalClientPublicKeyCAD: import.meta.env.VITE_GLOBAL_CLIENT_PUBLIC_KEY_CAD,
    globalClientPublicKeyUSD: import.meta.env.VITE_GLOBAL_CLIENT_PUBLIC_KEY_USD,
    gmApiKey: import.meta.env.VITE_GM_API_KEY,
    gmMapId: import.meta.env.VITE_GM_MAP_ID,
    version: import.meta.env.VITE_BUILD_ID,
  },
  azure: {
    containerName: import.meta.env.VITE_AZURE_CONTAINER,
    blobSasUrl: import.meta.env.VITE_AZURE_BLOBSASURL,
  },
}

// debug
// console.log(' ------------ ENVIRONMENT ------------')
// console.log(`auth domain: ${webConfig.auth.domain}`)
// console.log(`auth client id: ${webConfig.auth.client_id}`)
// console.log(`envName: ${webConfig.app.envName}`)
// console.log(`production: ${webConfig.app.production}`)
// console.log(`import.meta.env: ${JSON.stringify(import.meta.env)}`)
// console.log(' ------------ ENVIRONMENT ------------')

export default webConfig
