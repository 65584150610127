// #region enums
export enum CONTEXT {
  NONE = 'none',
  CLIENT = 'client',
  CORPORATE = 'corporate',
}
// #endregion

// #region interfaces
export interface INavMenu {
  name: string
  title: string
  icon?: string
  value: string
  context: CONTEXT
  disabled?: boolean // Used to disable/"grey out" and menu item
  hasAccess?: boolean // Used to check permissions and if the current user has access to even see the menu item
  badgeCount?: number | string
  listGroup?: string
  listGroupItem?: boolean
}
// #endregion
