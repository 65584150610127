<script setup lang="ts">
const properties = defineProps(['color'])
const color = ref(properties.color || 'grey98')
</script>

<template>
  <v-progress-circular
    indeterminate
    :color="color"
  />
</template>
