import { acceptHMRUpdate, defineStore } from 'pinia'
import { useDisplay } from 'vuetify'
import { router } from '~/router'
import { CONTEXT } from '~/types/navigation.types'

export const useNavigationStore = defineStore('navigation', () => {
  const { mobile } = useDisplay()
  const navBar = ref({
    isOpen: !mobile.value,
    toggleNav() {
      this.isOpen = !this.isOpen
    },
    isHidden: ref(false),
  })

  const contextStore = useContextStore()

  const back = (navigateTo: string) => {
    if (router.options.history.state.back !== null) {
      router.back()
      return
    }

    switch (contextStore.viewContext) {
      case CONTEXT.CLIENT:
        router.push({ name: navigateTo, params: { client: contextStore.selectedClientId } })
        break
      case CONTEXT.CORPORATE:
        router.push({ name: navigateTo, params: { corporate: contextStore.selectedCorporateId } })
        break
      default:
        router.push({ name: navigateTo })
    }
  }

  const navigateToListPage = (pageName: string) => {
    switch (contextStore.viewContext) {
      case CONTEXT.CLIENT:
        router.push({ name: `client-${pageName}` })
        break
      case CONTEXT.CORPORATE:
        router.push({ name: `corporate-${pageName}` })
        break
      default:
        router.push({ name: pageName })
    }
  }

  return {
    navBar,
    back,
    navigateToListPage,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useNavigationStore as any, import.meta.hot))
