// #region enums
export enum OLIVE_ERROR_CODE {
  UnspecifiedValidationFailure = 1200,
  AddExternalIdentifiersFromAuth_AuthNotFound = 2250,
  AddExternalIdentifiersFromAuth_AuthNotUnique = 2251,
  AddExternalIdentifiersFromAuth_IdentifierInUse = 2252,
  AddExternalIdentifiersFromAuth_NoNewIdentifiers = 9000,
  Incorrect_Mime_Type = 9001,
  Entity_Not_Loaded = 9002,
}
// #endregion

// #region interfaces
export interface IOliveError {
  hasError: boolean
  message: IVuetifyEmptyState
  code?: OLIVE_ERROR_CODE
}

export interface IVuetifyEmptyState {
  headline?: string
  title: string
  text?: string
  image?: string
}
// #endregion
