import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { IAccountStatement, IAccountStatementRequestParams } from '~/types/account.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'

export const useAccountStatementsStore = defineStore('accountStatements', () => {
  const oliveBaseUrl = '/account_statements'

  // #region state
  const accountStatements = ref<IAccountStatement[]>([])
  const accountStatementIdsCurrentPage = ref<string[]>([])

  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getAccountStatementById = computed(() => {
    return (id: string) => accountStatements.value.find(a => a.id === id)
  })

  const getAccountStatementsCurrentPage = computed(() => {
    return accountStatements.value.filter(a => accountStatementIdsCurrentPage.value.includes(a.id))
  })
  // #endregion

  // #region actions
  const loadAccountStatements = async (params?: Ref<IAccountStatementRequestParams>) => {
    const {
      response,
      error: accountError,
      run: loadAccountStatements,
    } = useOliveAPI<IPlatformResponse<IAccountStatement>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading account statements',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadAccountStatements()

    if (response.value && !accountError.value.hasError) {
      accountStatements.value = useArrayUnique([...response.value.items, ...accountStatements.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime)
        accountStatementIdsCurrentPage.value = [...response.value.items.map(a => a.id)]
    }
    else { error.value = accountError.value }

    isLoading.value = false
  }

  const loadAccountStatement = async (statementId: string) => {
    if (getAccountStatementById.value(statementId))
      return

    const {
      response,
      error: accountError,
      run: loadAccountStatement,
    } = useOliveAPI<IAccountStatement>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${statementId}`,
      errorMessage: 'Error loading account statement',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadAccountStatement()

    if (response.value && !accountError.value.hasError)
      accountStatements.value = useArrayUnique([response.value, ...accountStatements.value], (a, b) => a.id === b.id).value
    else
      error.value = accountError.value

    isLoading.value = false
  }

  const clearCurrentPage = () => {
    accountStatementIdsCurrentPage.value = []
  }
  // #endregion

  return {
    accountStatements,
    error,
    isLoading,
    getAccountStatementById,
    getAccountStatementsCurrentPage,
    loadAccountStatements,
    loadAccountStatement,
    clearCurrentPage,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAccountStatementsStore as any, import.meta.hot))
