import type { CURRENCY_CODE, OliveRequestParams } from './common.types'
import type { LOGO_SIZE } from './logo.types'
import type { OFFER_SSO_DESTINATION } from './offer.types'
import type { OFFER_MERCHANT_SUPPLIER_TYPE } from './offerMerchantSupplierType.types'
import type { IPaginationOptions } from './platform.types'
import type { CARD_SCHEME } from '~/types/card.types'

// Merchant Role
export const ROLE_ID = '08BA6EB3-9D0C-48B7-A7FA-4AD6F5DF065D'

// #region enum
export enum COUNTRY_CODE {
  CANADA = 'CA',
  USA = 'US',
}

export enum CLIENT_PAYMENT_NETWORK_COMPLIANCE_STATUS {
  NOT_STARTED = 'not_started',
  PREPARING = 'preparing',
  SUBMITTED = 'submitted',
  RESPONDING = 'responding',
  APPROVED = 'approved',
  PAUSED = 'paused',
  REJECTED = 'rejected',
}

export enum CLIENT_ROLE {
  ADMIN = 'Admin',
  CORPORATE = 'Corporate',
  MERCHANT = 'Merchant',
}

export enum CLIENT_STATUS {
  NEW = 'New',
  TESTING = 'Testing',
  LIVE = 'Live',
  LAUNCHED = 'Launched',
  DECOMMISSIONED = 'Decommissioned',
}

export enum SSO_METHOD {
  OICD = 'oidc',
}

export enum SSO_GRANT_TYPE {
  AUTHORIZATION_CODE = 'authorization_code',
}

export enum MEMBER_MAPPING {
  MEMBER_ID = 'member_id',
  EXTERNAL_MEMBER_ID = 'external_member_id',
}
// #endregion

// #region interfaces
export interface IClient {
  id: string
  created: string
  fullName: string
  email?: string
  role?: string
  phone?: string
  address?: string
  city?: string
  state?: string
  postCode?: string
  status: CLIENT_STATUS
  publicKey?: string
  logoSize?: LOGO_SIZE | undefined
  logoUrl?: string
  currencyCode?: string
  countryCode: COUNTRY_CODE
  defaultCashbackProgram?: boolean
  defaultRoundingProgram?: boolean
  defaultAssetBuildingProgramId?: string
  defaultMemberRoundingPeriodTotalMin?: number
  defaultMemberRoundingPeriodTotalMax?: number
  globalPrivacyPolicyUrl?: string
  customerSupportEmail?: string
  roundingEnabled: boolean
  loyaltyEnabled: boolean
  pfmEnabled: boolean
  matchingEnabled: boolean
  expenseEnabled: boolean
  chargeCardEnabled: boolean
  cardSchemes?: IClientCardSchemes
  autoRewardDisbursementCronEpression?: string
  autoRewardDisbursementOverridePaymentGatewayId?: string
  autoRewardDisbursementMinAmount?: number
}

export interface IClientSSOConfig {
  id?: string
  ssoMethod: SSO_METHOD
  clientId: string
  offerSSODestination: OFFER_SSO_DESTINATION
  memberMappingProperty: MEMBER_MAPPING
  sourceMappingProperty: string
  created?: Date
  updated?: Date
  oAuthClientId: string
  oAuthDomain: string
  oAuthGrantType: SSO_GRANT_TYPE
  scopes?: string
  audience?: string
}

export interface IClientRequestParams extends OliveRequestParams, IPaginationOptions {
  fullName?: string
  roundingEnabled?: boolean
  loyaltyEnabled?: boolean
  matchingEnabled?: boolean
  pfmEnabled?: boolean
  chargeCardEnabled?: boolean
  search?: string
  country?: string
  status?: string
}

export interface IClientCreateRequestParams extends OliveRequestParams {
  readonly roleId?: string
  fullName: string
  email: string
  address?: string
  city?: string
  state?: string
  postCode?: string
  phone?: string
  currencyCode: CURRENCY_CODE
  countryCode: COUNTRY_CODE
  customerSupportEmail?: string
  globalPrivacyPolicyUrl?: string
}

export interface IClientOfferRevenueShare {
  clientId: string
  supplierType: OFFER_MERCHANT_SUPPLIER_TYPE
  recipientPercentage: number
  publisherPercentage: number
}

export interface IAdminClient {
  id: string
  fullName: string
}

export interface IClientSelector {
  id: string
  name: string
  isActive: boolean
}

export interface IClientRequestParams extends OliveRequestParams, IPaginationOptions {
  clientId?: string
  fullName?: string
}
// #endregion

// #region settings interfaces
export interface IClientCardSchemes {
  visa?: IClientSupportedCardScheme
  master?: IClientSupportedCardScheme
  amex?: IClientSupportedCardScheme
  discover?: IClientSupportedCardScheme
}
export interface IClientSupportedCardScheme {
  vaultingEnabled: boolean
  roundingComplianceStatus: CLIENT_PAYMENT_NETWORK_COMPLIANCE_STATUS
  loyaltyComplianceStatus: CLIENT_PAYMENT_NETWORK_COMPLIANCE_STATUS
  pfmComplianceStatus: CLIENT_PAYMENT_NETWORK_COMPLIANCE_STATUS
  matchingComplianceStatus: CLIENT_PAYMENT_NETWORK_COMPLIANCE_STATUS
  // expenseComplianceStatus: CLIENT_PAYMENT_NETWORK_COMPLIANCE_STATUS
}
export interface IClientCardSchemesSettingsModel {
  clientId: string
  cardSchemes?: IClientCardSchemes
}

export interface IClientCardSchemesDataTableEntry extends IClientSupportedCardScheme {
  cardScheme: CARD_SCHEME
}
// #endregion
