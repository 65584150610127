import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as labs from 'vuetify/labs/components'
// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components'

export default createVuetify({
  components: {
    ...labs,
  },
  theme: {
    defaultTheme: 'light',
    variations: {
      colors: ['primary', 'secondary', 'whiteSmoke'],
      lighten: 5,
      darken: 4,
    },
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#3E563D',
          primaryDark: '#46704A',
          primaryLight: '#BACBA9',
          secondary: '#BACBA9',
          success: '#0A8852',
          error: '#D00E17',
          warning: '#FFC107',
          danger: '#761C17',
          info: '#4E545E',
          disabled: '#B6B6B6',
          aliceBlue: '#FAFBFC',
          midnightExpress: '#091E42',
          nightRider: '#2D2D2D',
          grey98: '#FAFAFA',
          grey17: '#2B2B2B',
          huntergreen: '#3E563D',
          nyanza: '#E1F4CB',
          charcoal: '#2C3E50',
          sunglow: '#FECD46',
          whiteSmoke: '#F5F5F5',
          silver: '#C0C0C0',
        },
        variables: {
          notificationBgColor: 'white', // using hex as a color breaks the snackbar
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#3E563D',
          primaryDark: '#A9C2A8',
          primaryLight: '#BACBA9',
          secondary: '#BACBA9',
          success: '#0A8852',
          error: '#D00E17',
          warning: '#FFC107',
          danger: '#F0B7B4',
          info: '#535353',
          disabled: '#494949',
          aliceBlue: '#040607',
          midnightExpress: '#F6E1BD',
          nightRider: '#D2D2D2',
          grey98: '#050505',
          grey17: '#D4D4D4',
          huntergreen: '#A9C2A8',
          nyanza: '#20320B',
          charcoal: '#B0C2D4',
          sunglow: '#B78601',
          whiteSmoke: '#0A0A0A',
          silver: '#3F3F3F',
        },
      },
    },
  },
  display: {
    mobileBreakpoint: 'md',
  },
  aliases: {
    AddButton: VBtn,
  },
  defaults: {
    AddButton: {
      elevation: '1',
      variant: 'outlined',
      color: 'primary',
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})
