<script setup lang="ts">
import { CARD_SCHEME } from '~/types/card.types'
import { SUPPORTED_LOCALE } from '~/types/common.types'

interface IOliveCreditCard {
  scheme: string
  last4Digits?: string
  expiryMonth?: number
  expiryYear?: number
  expiryDate?: string
  nickname?: string
  locale?: SUPPORTED_LOCALE
  width?: string
}
const {
  scheme: cardScheme,
  last4Digits: cardLast4Digits = '0000',
  expiryMonth: cardExpiryMonth = 12,
  expiryYear: cardExpiryYear = new Date().getFullYear(),
  expiryDate: cardExpiryDate,
  nickname: cardNickname,
  locale = SUPPORTED_LOCALE.EN_CA,
  width = '340px',
} = defineProps<IOliveCreditCard>()

const DEFAULT_CARD_WIDTH = 340
const MIN_CARD_WIDTH = 240

const cardNumber = ref(`#### #### #### ${cardLast4Digits}`)

const formattedExpiryDate = computed(() => {
  if (cardExpiryDate)
    return cardExpiryDate

  const formattedMonth = String(cardExpiryMonth).padStart(2, '0')
  const formattedYear = String(cardExpiryYear).slice(-2)
  return `${formattedMonth}/${formattedYear}`
})

function normalizeCardScheme(scheme: string): CARD_SCHEME | undefined {
  switch (scheme.toLowerCase()) {
    case 'visa':
      return CARD_SCHEME.VISA
    case 'master':
    case 'mastercard':
      return CARD_SCHEME.MASTERCARD
    case 'amex':
    case 'american_express':
      return CARD_SCHEME.AMEX
    case 'discover':
      return CARD_SCHEME.DISCOVER
    default:
      return undefined
  }
}

const cardType = ref(normalizeCardScheme(cardScheme))

function formatCardNumber() {
  switch (cardType.value) {
    case CARD_SCHEME.AMEX:
      cardNumber.value = `#### ###### #${cardLast4Digits}`
      break
    default:
      cardNumber.value = `#### #### #### ${cardLast4Digits}`
  }
}
formatCardNumber()

const cardNumberArray = computed(() => cardNumber.value.split(''))

const cardWidth = computed(() => {
  const parsedWidth = Number.parseFloat(width)
  return `${Math.max(MIN_CARD_WIDTH, parsedWidth)}px`
})

watch(() => cardScheme, () => {
  cardType.value = normalizeCardScheme(cardScheme)
  formatCardNumber()
})
</script>

<template>
  <div
    class="credit-card-container"
    :style="{
      '--card-width': cardWidth,
      '--scale-factor': `calc(${cardWidth.replace('px', '')} / ${DEFAULT_CARD_WIDTH})`,
    }"
  >
    <div class="credit-card">
      <div class="card-details">
        <div class="card-scheme">
          <div
            v-if="cardType === CARD_SCHEME.VISA"
            class="olv-creditcardlogo"
            data-logo="Visa"
            aria-hidden="true"
          />

          <div
            v-if="cardType === CARD_SCHEME.MASTERCARD"
            class="olv-creditcardlogo"
            data-logo="Mastercard"
            aria-hidden="true"
          />

          <div
            v-if="cardType === CARD_SCHEME.AMEX"
            class="olv-creditcardlogo"
            data-logo="Amex"
            aria-hidden="true"
          />

          <div
            v-if="cardType === CARD_SCHEME.DISCOVER"
            class="olv-creditcardlogo"
            data-logo="Discover"
            aria-hidden="true"
          />
        </div>
        <div
          id="cardNumberLabel"
          class="card-number"
        >
          <span
            v-for="(char, index) in cardNumberArray"
            :key="index"
            class="card-item-numberItem"
            :class="{ '-spacing': char === ' ' }"
          >
            {{ char }}
          </span>
        </div>
        <div class="card-footer">
          <div
            class="card-nickname"
          >
            {{ cardNickname }}
          </div>
          <div class="card-expiry">
            <div class="card-expiry-valid-thru">
              {{ locale === SUPPORTED_LOCALE.FR_CA ? 'expire a fin' : 'valid thru' }}
            </div>
            <div class="card-expiry-date">
              {{ formattedExpiryDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use  '~/styles/olive-credit-card.scss'
</style>
