import type { OliveRequestParams } from './common.types'
import type { IPaginationOptions, ISearchOptions } from './platform.types'
import type { IRoundUpRule } from './roundUpRule.types'

// #region const
export const MISSING_EXPIRY = 'Missing'
// #endregion

// #region enums
export enum CARD_INTEGRATION_STATUS {
  COMPLETED = 'completed',
  INCOMPLETE = 'incomplete',
  NOT_QUALIFIED = 'not_qualified',
}

export enum CARD_SCHEME {
  VISA = 'visa',
  MASTERCARD = 'master',
  AMEX = 'amex',
  DISCOVER = 'discover',
}

export enum VAULT_CARD_SCHEME {
  UNKNOWN = 'unknown',
  VISA = 'visa',
  MASTERCARD = 'master',
  AMEX = 'american_express',
  DISCOVER = 'discover',
}

export enum CARD_FORM_TYPE_PREVIEW {
  ADD_CARD_FORM = 'add_card_form',
  UPDATE_CARD_CONSENT_FORM = 'update_card_consent_form',
}

export enum EXTERNAL_IDENTIFIER_TYPE {
  MC_MID_MERCH_NAME_LOC = 'mastercard_mid_merch_name_loc',
  VISA_STORE_ID = 'visa_store_id',
  MC_LOCATION_ID = 'mastercard_location_id',
}

export enum EXTERNAL_ID_LABEL {
  IDENTIFIER = 'Identifier',
  MERCHANT_ID = 'Merchant Id',
}
// #endregion

// #region interfaces
export interface ICard {
  id: string
  memberId: string
  nickname: string
  cardToken: string
  clientId?: string
  created?: string
  updated?: string
  last4Digits: number
  scheme: CARD_SCHEME
  expiryMonth: number
  expiryYear: number
  active: boolean
  integrationStatus: CARD_INTEGRATION_STATUS
  issuer: string
  roundUpRules: IRoundUpRule[]
  firstTransactionDate?: string
  lastTransactionDate?: string
  transactionCount: number
  default: boolean
  chargeCardConsent: boolean
  roundingConsent: boolean
  loyaltyConsent: boolean
  matchingConsent: boolean
  pfmConsent: boolean
  expenseConsent: boolean
}

export interface ICardRequestParams extends OliveRequestParams, IPaginationOptions, ISearchOptions {
  cardId?: string[]
  clientId?: string
  memberId?: string
  status?: string
  active?: boolean
  integrationStatus?: CARD_INTEGRATION_STATUS
  last4Digits?: number
  scheme?: CARD_SCHEME
  sort?: string
  created?: string | string[]
  firstTransactionDate?: string | string[]
  lastTransactionDate?: string | string[]
  transactionCount?: number
  expiryMonth?: number
  expiryYear?: number
  consentIsCurrent?: boolean
}
// #endregion
