import webConfig from '~/config'

const auth0Config = {
  domain: webConfig.auth.domain,
  clientId: webConfig.auth.client_id,
  cacheLocation: webConfig.auth.cache_location,
  useRefreshTokens: webConfig.auth.useRefreshTokens,
  useRefreshTokensFallback: webConfig.auth.useRefreshTokens,
  authorizationParams: {
    audience: webConfig.auth.audience,
    redirect_uri: webConfig.auth.redirect_uri,
    scope: 'openid profile email offline_access',
  },
}

export default auth0Config
