import type { RawAxiosRequestHeaders } from 'axios'
import type { CacheRequestConfig } from 'axios-cache-interceptor'
import webConfig from '~/config'
import type { IBrand, IBrandRequestParams } from '~/types/brand.types'
import type { ICardTransaction, ICardTransactionRequestParams } from '~/types/cardTransaction.types'

import type { IClient, IClientOfferRevenueShare, IClientRequestParams } from '~/types/client.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { ICorporate, ICorporateRequestParams } from '~/types/corporate.types'
import type {
  IMCMerchantDetails,
  IMCMerchantDetailsRequestParams,
  IMCMerchantDetailsUnmapped,
  IMCMerchantDetailsUnmappedRequestParams,
  IVisaMerchantDetails,
  IVisaMerchantDetailsRequestParams,
  IVisaMerchantDetailsUnmapped,
  IVisaMerchantDetailsUnmappedRequestParams,
} from '~/types/merchantDetails.types'
import type {
  IContributionByCategoryOptions,
  ICountOptions,
  IPaginationOptions,
  IPlatformResponse,
} from '~/types/platform.types'
import type { IStore, IStoreRequestParams } from '~/types/store.types'
import HttpClient from './axios'

const headers: RawAxiosRequestHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'App-Version': webConfig.app.version,
}

class PlatformApi extends HttpClient {
  public constructor() {
    super(webConfig.api.platform_api_url, headers)
  }

  public run = async <T>(method: HTTP_METHOD, url: string, data: any, requestConfig: CacheRequestConfig) => {
    switch (method) {
      case HTTP_METHOD.POST:
        return await this.api.post<T>(url, data, requestConfig)
      case HTTP_METHOD.PUT:
        return await this.api.put<T>(url, data, requestConfig)
      case HTTP_METHOD.DELETE:
        return await this.api.delete<T>(url, requestConfig)
      case HTTP_METHOD.PATCH:
        return await this.api.patch<T>(url, data, requestConfig)
      case HTTP_METHOD.GET:
      default:
        return await this.api.get<T>(url, requestConfig)
    }
  }

  // #region Keys
  public getPublicKey = async (clientId: string) => {
    return await this.api.get<string>(`/clients/${clientId}/public_key`)
  }
  // #endregion

  // #region Metrics
  public getContributionCountsByInterval = async (interval: string, countOptions: ICountOptions, signal?: AbortSignal) => {
    const params: ICountOptions = countOptions
    return await this.api.get(`/contributions/totals/${interval}`, { params, signal })
  }

  public getCardsCounts = async (countOptions: ICountOptions, signal?: AbortSignal) => {
    const params: ICountOptions = countOptions
    return await this.api.get('/cards/counts/', { params, signal })
  }

  public getCardsCountsByInterval = async (interval: string, countOptions: ICountOptions, signal?: AbortSignal) => {
    const params: ICountOptions = countOptions
    return await this.api.get(`/cards/counts/${interval}`, { params, signal })
  }

  public getMembersCounts = async (membersOptions: ICountOptions, signal?: AbortSignal) => {
    const params: ICountOptions = membersOptions
    return await this.api.get('/members/counts/', { params, signal })
  }

  public getMembersCountsByInterval = async (interval: string, countOptions: ICountOptions, signal?: AbortSignal) => {
    const params: ICountOptions = countOptions
    return await this.api.get(`/members/counts/${interval}`, { params, signal })
  }

  public getRedemptionMetricsByInterval = async (interval: string, options: IContributionByCategoryOptions, signal?: AbortSignal) => {
    const params: IContributionByCategoryOptions = options
    return await this.api.get(`/contributions/totals_mcc/${interval}`, { params, signal })
  }

  public getPurchaseCategoriesByInterval = async (interval: string, options: IContributionByCategoryOptions, signal?: AbortSignal) => {
    const params: IContributionByCategoryOptions = options
    return await this.api.get(`/transactions/totals_mcc/${interval}`, { params, signal })
  }

  public getRedemptionMetricsByBrandInterval = async (interval: string, options: IContributionByCategoryOptions, signal?: AbortSignal) => {
    const params: IContributionByCategoryOptions = options
    return await this.api.get(`/contributions/totals_brand/${interval}`, { params, signal })
  }

  public getRedemptionMetricsByStoreInterval = async (interval: string, options: IContributionByCategoryOptions, signal?: AbortSignal) => {
    const params: IContributionByCategoryOptions = options
    return await this.api.get(`/contributions/totals_store/${interval}`, { params, signal })
  }
  // #endregion

  public unassignClientFromUser = async (userId: string, clientId: string) => {
    return await this.api.delete<any>(`/users/${userId}/clients/${clientId}`)
  }
  // #endregion

  // #region VISA
  public getVisaMerchantDetails = async (params: IVisaMerchantDetailsRequestParams) => {
    return await this.api.get<IVisaMerchantDetails[]>('/visa/merchant_details', { params })
  }

  public getVisaUnmappedMerchantDetails = async (reqParams: IVisaMerchantDetailsUnmappedRequestParams, pagination?: IPaginationOptions) => {
    const params = {
      ...reqParams,
      pageSize: pagination?.pageSize,
      pageNumber: pagination?.pageNumber,
    }
    return await this.api.get<IPlatformResponse<IVisaMerchantDetailsUnmapped>>('/visa/merchant_details/unmapped', { params })
  }
  // #endregion

  // #region MASTERCARD
  public getMCUnmappedMerchantDetails = async (reqParams: IMCMerchantDetailsUnmappedRequestParams, pagination?: IPaginationOptions) => {
    const params = {
      ...reqParams,
      pageSize: pagination?.pageSize,
      pageNumber: pagination?.pageNumber,
    }
    return await this.api.get<IPlatformResponse<IMCMerchantDetailsUnmapped>>('/mastercard/merchant_details/unmapped', { params })
  }

  public getMCSearchMerchantDetails = async (reqParams: IMCMerchantDetailsRequestParams, pagination: IPaginationOptions) => {
    const params = {
      ...reqParams,
      pageSize: pagination?.pageSize,
      pageNumber: pagination?.pageNumber,
      radiusSearch: !!reqParams.latitude && !!reqParams.longitude,
    } as IMCMerchantDetailsRequestParams
    return await this.api.get<IPlatformResponse<IMCMerchantDetails>>('/mastercard/merchant_details', { params })
  }

  public getMCLocationMerchantDetails = async (reqParams: IMCMerchantDetailsRequestParams) => {
    return await this.api.get<IMCMerchantDetails>(`/mastercard/merchant_details/${reqParams.locationId}`)
  }
  // #endregion

  // #region ClientSelector
  public loadClients = async (params?: IClientRequestParams, signal?: AbortSignal) => {
    const {
      response,
      run: loadClients,
    } = useOliveAPI<IPlatformResponse<IClient>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder('/clients', params),
      errorMessage: 'Error loading clients',
    }, signal)
    await loadClients()
    const clients = response.value?.items.slice().sort((a, b) => {
      if (a.fullName < b.fullName)
        return -1
      if (a.fullName > b.fullName)
        return 1
      return 0
    })
    return { clients, total: response.value?.totalNumberOfRecords }
  }
  // #endregion

  // #region BrandSelector
  public loadBrands = async (params?: IBrandRequestParams, signal?: AbortSignal) => {
    const {
      response,
      run: loadBrands,
    } = useOliveAPI<IPlatformResponse<IBrand>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder('/brands', params),
      errorMessage: 'Error loading brands',
    }, signal)
    await loadBrands()
    return { brands: response.value?.items, total: response.value?.totalNumberOfRecords }
  }
  // #endregion

  // #region StoreSelector
  public loadStores = async (params?: IStoreRequestParams, signal?: AbortSignal) => {
    const {
      response,
      run: loadStores,
    } = useOliveAPI<IPlatformResponse<IStore>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder('/stores', params),
      errorMessage: 'Error loading stores',
    }, signal)
    await loadStores()
    return { stores: response.value?.items, total: response.value?.totalNumberOfRecords }
  }
  // #endregion

  // #region CorporateSelector
  public loadCorporates = async (params?: ICorporateRequestParams, signal?: AbortSignal) => {
    const {
      response,
      run: loadCorporates,
    } = useOliveAPI<IPlatformResponse<ICorporate>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder('/corporates', params),
      errorMessage: 'Error loading corporates',
    }, signal)
    await loadCorporates()
    return { corporates: response.value?.items, total: response.value?.totalNumberOfRecords }
  }
  // #endregion

  // #region RevenueShares
  public getAllClientOfferRevenueShares = async () => {
    const {
      response,
      run: getAllClientOfferRevenueShares,
    } = useOliveAPI<IClientOfferRevenueShare[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`/clients/offer_revenue_shares/`),
      errorMessage: 'Failed to load offer revenue shares',
    })

    await getAllClientOfferRevenueShares()

    return response.value
  }

  public getClientOfferRevenueShares = async (clientId: string) => {
    const {
      response,
      run: getClientOfferRevenueShares,
    } = useOliveAPI<IClientOfferRevenueShare[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`/clients/${clientId}/offer_revenue_shares/`),
      errorMessage: 'Failed to load offer revenue shares',
    })

    await getClientOfferRevenueShares()

    return response.value
  }

  public putClientOfferRevenueShares = async (clientId: string, putObj: IClientOfferRevenueShare[]) => {
    const {
      response,
      run: putClientOfferRevenuShares,
    } = useOliveAPI<IClientOfferRevenueShare[]>({
      method: HTTP_METHOD.PUT,
      url: useOliveURLRequestBuilder(`/clients/${clientId}/offer_revenue_shares/`),
      data: putObj,
      errorMessage: 'Failed to save offer revenue shares',
    })

    await putClientOfferRevenuShares()

    return response.value
  }
  // #endregion

  // #region Transactions
  public getTransactions = async (params?: ICardTransactionRequestParams, signal?: AbortSignal) => {
    const {
      response,
      run: getTransactions,
    } = useOliveAPI<IPlatformResponse<ICardTransaction>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder('/transactions', params),
      errorMessage: 'Error loading transactions',
    }, signal)
    await getTransactions()
    return { transactions: response.value?.items, total: response.value?.totalNumberOfRecords }
  }
  // #endregion
}

const singleton = new PlatformApi()
export default singleton
