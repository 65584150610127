<script setup lang="ts">
import { AuthenticationState } from 'vue-auth0-plugin'
import { redirectToNoAccessPage } from '~/router'

const selectedClientId = useContextStore().selectedClientId
const clients = useClientsStore().clients

const selectedCorporateId = useContextStore().selectedCorporateId
const corporates = useCorporatesStore().corporates

const allClientsAccess = useContextStore().allClientsAccess
const authenticated = AuthenticationState.authenticated
const isAdmin = useUserStore().canAccessAllClients
const router = useRouter()

onBeforeMount(
  async () => {
    if (allClientsAccess && isAdmin)
      router.replace({ name: 'clients' })
    else if (selectedClientId)
      router.replace({ name: 'client-overview', params: { client: selectedClientId } })
    else if (selectedCorporateId)
      router.replace({ name: 'corporate-overview', params: { corporate: selectedCorporateId } })
    else if (authenticated && (clients.length === 0 && corporates.length === 0))
      router.replace({ name: 'welcome' })
    else
      redirectToNoAccessPage()
  },
)
</script>

<template>
  <v-row
    align-content="center"
    justify-content="center"
  >
    <v-col class="d-flex flex-column justify-center align-center pt-16">
      <loading-spinner />
    </v-col>
  </v-row>
</template>

<route lang="yaml">
name: index
meta:
  layout: empty
  requiresAuth: true
</route>
