import type { CountryCode } from 'libphonenumber-js'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

function convertPhoneNumberToE164(phone: string, countryCode: string): string {
  const parsedPhoneNumber = parsePhoneNumberFromString(phone ?? '', countryCode as CountryCode)
  if (parsedPhoneNumber) {
    return parsedPhoneNumber.format('E.164')
  }
  return ''
}

export {
  convertPhoneNumberToE164,
}
