import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useOliveAPI } from '~/composables/useOliveAPI'
import { useOliveURLRequestBuilder } from '~/composables/useOliveURLRequestBuilder'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import type { IWebhook, IWebhookEvent, IWebhookLog, IWebhookLogRequestParams, IWebhookRequestParams, IWebhookUpdateRequestParams } from '~/types/webhook.types'

export const useWebhookStore = defineStore('webhooks', () => {
  const oliveBaseUrl = '/webhooks'

  // #region state
  const webhooks = ref<IWebhook[]>([])
  const webhookIdsCurrentPage = ref<string[]>([])
  const numberOfWebhooks = ref<number | undefined>()
  const selectedWebhookId = ref<string>()

  const webhookLogs = ref<IWebhookLog[]>([])
  const webhookLogIdsCurrentPage = ref<string[]>([])
  const numberOfWebhookLogs = ref<number | undefined>()

  const webhookEvents = ref<IWebhookEvent[]>([])
  const isLoadingWebhookEvents = ref(false)
  const hasLoadedWebhookEvents = ref(false)

  const error = ref<IOliveError>(getOliveError())
  const eventError = ref<IOliveError>(getOliveError())
  const isLoadingListItems = ref(false)

  const isLoading = ref(false)

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getWebhookById = computed(() => {
    return (id: string) => webhooks.value.find(w => w.id === id)
  })

  const getWebhookLogById = computed(() => {
    return (id: string) => webhookLogs.value.find(w => w.id === id)
  })

  const getWebhooksCurrentPage = computed (() => {
    return webhooks.value.filter(f => webhookIdsCurrentPage.value.includes(f.id))
  })

  const getWebhookLogsCurrentPage = computed (() => {
    return webhookLogs.value.filter(f => webhookLogIdsCurrentPage.value.includes(f.id))
  })

  const getSupportedWebhookEvents = computed(() => {
    return webhookEvents.value.filter(f => f.event !== 'charge_successful' && f.event !== 'charge_failure')
  })

  const getSelectedWebhook = computed(() => {
    if (selectedWebhookId.value)
      return getWebhookById.value(selectedWebhookId.value)
  })
  // #endregion

  // #region actions
  const loadWebhookEvents = async () => {
    const {
      response,
      error: webhookError,
      run: loadWebhookEvents,
    } = useOliveAPI<IWebhookEvent[]>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/events`,
      errorMessage: 'Error loading webhooks events',
    })
    isLoadingWebhookEvents.value = true
    eventError.value = getOliveError()

    await loadWebhookEvents()

    if (response.value && !webhookError.value.hasError) {
      webhookEvents.value
      = useSorted(
          useArrayUnique(
            [...response.value, ...webhookEvents.value],
            (a, b) => a.event === b.event,
          ),
          (c, d) => {
            if (c.event < d.event)
              return -1
            if (c.event > d.event)
              return 1
            return 0
          },
        ).value
      hasLoadedWebhookEvents.value = true
    }
    else { eventError.value = webhookError.value }

    isLoadingWebhookEvents.value = false
  }

  const loadWebhooks = async (params?: Ref<IWebhookRequestParams> | IWebhookRequestParams) => {
    const {
      response,
      error: webhookError,
      run: loadWebhooks,
    } = useOliveAPI<IPlatformResponse<IWebhook>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading webhooks',
    })
    isLoadingListItems.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadWebhooks()

    if (response.value?.items && !webhookError.value.hasError) {
      webhooks.value = useArrayUnique([...response.value.items, ...webhooks.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        webhookIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfWebhooks.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = webhookError.value }

    isLoadingListItems.value = false
  }

  const loadWebhook = async (id: string) => {
    selectedWebhookId.value = id

    if (getWebhookById.value(id))
      return

    const {
      response,
      error: webhookError,
      run: loadWebhook,
    } = useOliveAPI<IWebhook>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading webhook',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadWebhook()

    if (response.value && !webhookError.value.hasError)
      webhooks.value = useArrayUnique([response.value, ...webhooks.value], (a, b) => a.id === b.id).value
    else
      error.value = webhookError.value

    isLoading.value = false
  }

  const loadWebhookLogs = async (params?: Ref<IWebhookLogRequestParams> | IWebhookLogRequestParams, webhookId?: string) => {
    const {
      response,
      error: webhookLogError,
      run: loadWebhookLogs,
    } = useOliveAPI<IPlatformResponse<IWebhookLog>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(webhookId ? `${oliveBaseUrl}/${webhookId}/logs` : `${oliveBaseUrl}/logs`, params),
      errorMessage: 'Error loading webhooks',
    })
    isLoadingListItems.value = true
    error.value = webhookLogError.value

    await loadWebhookLogs()

    if (response.value?.items && !webhookLogError.value.hasError) {
      webhookLogs.value = useArrayUnique([...response.value.items, ...webhookLogs.value], (a, b) => a.id === b.id).value
      webhookLogIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
      numberOfWebhookLogs.value = response.value.totalNumberOfRecords
    }
    else { error.value = webhookLogError.value }

    isLoadingListItems.value = false
  }

  const addWebhook = async (webhook: IWebhook) => {
    const {
      response,
      error: webhookError,
      run: addWebhook,
    } = useOliveAPI<IWebhook>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/subscribe`,
      data: webhook,
      successMessage: 'Webhook added successfully',
      errorMessage: 'Failed to create a webhook',
    })
    isLoadingListItems.value = true
    error.value = getOliveError()

    await addWebhook()

    if (response.value && !webhookError.value.hasError) {
      webhooks.value = useArrayUnique([response.value, ...webhooks.value], (a, b) => a.id === b.id).value
      selectedWebhookId.value = response.value.id
    }
    else {
      error.value = webhookError.value
    }

    isLoadingListItems.value = false
  }

  const udpateWebhook = async (webhookId: string, webhook: IWebhookUpdateRequestParams) => {
    selectedWebhookId.value = webhookId

    const {
      response,
      error: webhookError,
      run: updateWebhook,
    } = useOliveAPI<IWebhook>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${webhookId}`,
      data: webhook,
      successMessage: 'Webhook updated successfully',
      errorMessage: 'Failed to update webhook',
    })
    isLoadingListItems.value = true
    error.value = getOliveError()

    await updateWebhook()

    if (response.value && !webhookError.value.hasError)
      webhooks.value = useArrayUnique([response.value, ...webhooks.value], (a, b) => a.id === b.id).value
    else
      error.value = webhookError.value

    isLoadingListItems.value = false
  }

  const deleteWebhook = async (webhookId: string) => {
    const {
      error: webhookError,
      run: deleteWebhook,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${webhookId}`,
      successMessage: 'Webhook deleted successfully',
      errorMessage: 'Failed to delete webhook',
    })
    isLoadingListItems.value = true
    error.value = getOliveError()

    await deleteWebhook()

    if (!webhookError.value.hasError)
      webhooks.value = webhooks.value.filter(w => w.id !== webhookId)
    else
      error.value = webhookError.value

    isLoadingListItems.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    webhookIdsCurrentPage.value = []
    webhookLogIdsCurrentPage.value = []
  }

  const clearSelectedWebhook = () => {
    selectedWebhookId.value = undefined
  }
  // #endregion

  return {
    webhooks,
    getWebhooksCurrentPage,
    getWebhookLogsCurrentPage,
    numberOfWebhooks,
    numberOfWebhookLogs,
    getSupportedWebhookEvents,
    getSelectedWebhook,
    clearSelectedWebhook,
    error,
    eventError,
    isLoadingListItems,
    isLoading,
    isLoadingWebhookEvents,
    hasLoadedWebhookEvents,
    getWebhookById,
    getWebhookLogById,
    loadWebhooks,
    loadWebhook,
    loadWebhookLogs,
    loadWebhookEvents,
    addWebhook,
    udpateWebhook,
    deleteWebhook,
    clearCurrentPage,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useWebhookStore as any, import.meta.hot))
