import { OFFER_REWARD_STATUS } from './offer.types'

// #region enums
export enum OFFER_REDEMPTION_STATUS {
  ELIGIBLE = 'eligible',
  EARNED = 'earned',
  SUBMITTED_TO_MERCHANT = 'submitted',
  CONFIRMED = 'confirmed',
  FUNDED = 'funded',
  DISTRIBUTED_TO_ACCOUNT = 'distributed',
  REJECTED = 'rejected',
}
// #endregion

// #region functions
export function Mapper_OFFER_REDEMPTION_STATUS_to_OFFER_REWARD_STATUS(offerRedemptionSummary: OFFER_REDEMPTION_STATUS): OFFER_REWARD_STATUS[] {
  switch (offerRedemptionSummary) {
    case OFFER_REDEMPTION_STATUS.ELIGIBLE:
      return []
    case OFFER_REDEMPTION_STATUS.EARNED:
      return [OFFER_REWARD_STATUS.PENDING_REVIEW, OFFER_REWARD_STATUS.EARNED_PENDING_SETTLEMENT]
    case OFFER_REDEMPTION_STATUS.SUBMITTED_TO_MERCHANT:
      return [OFFER_REWARD_STATUS.PENDING_MERCHANT_APPROVAL]
    case OFFER_REDEMPTION_STATUS.CONFIRMED:
      return [OFFER_REWARD_STATUS.PENDING_MERCHANT_FUNDING]
    case OFFER_REDEMPTION_STATUS.FUNDED:
      return [OFFER_REWARD_STATUS.PENDING_TRANSFER_TO_PUBLISHER, OFFER_REWARD_STATUS.DISTRIBUTED_TO_PUBLISHER, OFFER_REWARD_STATUS.DISTRIBUTED_TO_OLIVE]
    case OFFER_REDEMPTION_STATUS.DISTRIBUTED_TO_ACCOUNT:
      return [OFFER_REWARD_STATUS.DISTRIBUTED_TO_MEMBER_DISTRIBUTOR]
    case OFFER_REDEMPTION_STATUS.REJECTED:
      return [OFFER_REWARD_STATUS.REJECTED]
  }
}

export function getOfferRedemptionStatusTitle(title: OFFER_REDEMPTION_STATUS) {
  switch (title) {
    case OFFER_REDEMPTION_STATUS.ELIGIBLE:
      return 'Eligible Transactions'
    case OFFER_REDEMPTION_STATUS.EARNED:
      return 'Earned'
    case OFFER_REDEMPTION_STATUS.SUBMITTED_TO_MERCHANT:
      return 'Submitted to Merchant'
    case OFFER_REDEMPTION_STATUS.CONFIRMED:
      return 'Confirmed'
    case OFFER_REDEMPTION_STATUS.FUNDED:
      return 'Funded'
    case OFFER_REDEMPTION_STATUS.DISTRIBUTED_TO_ACCOUNT :
      return 'Paid to Client'
    case OFFER_REDEMPTION_STATUS.REJECTED:
      return 'Rejected'
  }
}

export function getOfferRedemptionStatusTooltip(title: OFFER_REDEMPTION_STATUS) {
  switch (title) {
    case OFFER_REDEMPTION_STATUS.EARNED:
      return 'Represents the reward statuses of \'pending_review\' and \'earned_pending_settlement\''
    case OFFER_REDEMPTION_STATUS.SUBMITTED_TO_MERCHANT:
      return 'Represents the reward status of \'pending_merchant_approval\''
    case OFFER_REDEMPTION_STATUS.CONFIRMED:
      return 'Represents the reward status of \'pending_merchant_funding\''
    case OFFER_REDEMPTION_STATUS.FUNDED:
      return 'Represents the reward statuses of \'pending_transfer_to_publisher\', \'distributed_to_publisher\', and \'distributed_to_olive\''
    case OFFER_REDEMPTION_STATUS.DISTRIBUTED_TO_ACCOUNT :
      return 'Represents the reward status of \'distributed_to_member_distributor\''
    case OFFER_REDEMPTION_STATUS.REJECTED:
      return 'Represents the reward status of \'rejected\''
    default:
      return ''
  }
}
// #endregion

// #region interfaces
export interface IOfferRedemptionSummary {
  title: string
  count: number
  amount: string
  distributed?: string
  showDistributed?: boolean
  isRejected: boolean
  type: OFFER_REDEMPTION_STATUS
  tooltip: string
}
// #endregion
