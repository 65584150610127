import { acceptHMRUpdate, defineStore } from 'pinia'
import type { IGoogleMarker } from '~/types/common.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IOffer, IOfferRequestParams } from '~/types/offer.types'
import { OFFER_STATE } from '~/types/offer.types'
import type { IPlatformResponse } from '~/types/platform.types'

const BATCH_SIZE = 25

export const useOffersStore = defineStore('offers', () => {
  const oliveBaseUrl = '/offers'

  // #region state
  const offers = ref<IOffer[]>([])
  const offerIdsCurrentPage = ref<string[]>([])
  const selectedOfferId = ref<string>()
  const updatedOffer = ref<IOffer>()
  const approvedOfferState = ref<OFFER_STATE>()
  const numberOfOffers = ref<number>(0)
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IOfferRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getOfferById = computed(() => {
    return (id: string) => offers.value.find(o => o.id === id)
  })

  const getOffersByIds = computed(() => {
    return (ids: string[]) => offers.value.filter(o => ids.includes(o.id))
  })

  const getOffersCurrentPage = computed (() => {
    return offers.value.filter(o => offerIdsCurrentPage.value.includes(o.id))
  })

  const getOffersWithoutGeolocationCurrentPage = computed (() => {
    return offers.value
      .filter(o => offerIdsCurrentPage.value.includes(o.id))
      .filter(o => o.storeDetails.every(s =>
        !s.geoLocation
        || Object.keys(s.geoLocation).length === 0),
      )
  })

  const getOfferMarkers = computed(() => {
    if (getOffersCurrentPage.value) {
      const filteredOffers = getOffersCurrentPage.value
        .filter(o =>
          o.storeDetails.some(s =>
            s.geoLocation && s.geoLocation.latitude !== undefined && s.geoLocation.longitude !== undefined))

      return filteredOffers.flatMap(o =>
        o.storeDetails.map(s => ({
          position: {
            lat: s.geoLocation?.latitude,
            lng: s.geoLocation?.longitude,
          },
          offer: o,
        } as IGoogleMarker)),
      )
    }
  })

  const getSelectedOffer = computed(() => {
    if (selectedOfferId.value)
      return getOfferById.value(selectedOfferId.value)
  })
  // #endregion

  // #region actions
  const loadOffers = async (params?: IOfferRequestParams) => {
    const {
      response,
      error: offerError,
      run: loadOffers,
    } = useOliveAPI<IPlatformResponse<IOffer>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading offers',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadOffers()

    if (response.value?.items && !offerError.value.hasError) {
      offers.value = useArrayUnique([...response.value.items, ...offers.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        offerIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfOffers.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = offerError.value }

    isLoading.value = false
  }

  const loadOffersByIds = async (ids: string[]) => {
    const offersIdsFiltered = [...new Set(ids.filter(b => !getOfferById.value(b)).map(t => t as string).filter(s => !(s === null || s === undefined)))]

    if (offersIdsFiltered.length === 0)
      return

    const batches = []
    do {
      batches.push(loadOffers({ pageSize: offersIdsFiltered.slice(0, BATCH_SIZE).length, offerId: offersIdsFiltered.slice(0, BATCH_SIZE) }))
      offersIdsFiltered.splice(0, BATCH_SIZE)
    }
    while (offersIdsFiltered.length > 0)
    await Promise.all(batches)
  }

  const loadOffer = async (id: string) => {
    selectedOfferId.value = id

    if (getOfferById.value(id))
      return

    const {
      response,
      error: offerError,
      run: loadOffer,
    } = useOliveAPI<IOffer>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading offer',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadOffer()

    if (response.value && !offerError.value.hasError)
      offers.value = useArrayUnique([response.value, ...offers.value], (a, b) => a.id === b.id).value
    else
      error.value = offerError.value

    isLoading.value = false
  }

  const approveOffer = async (offerId: string) => {
    const {
      response,
      error: offerError,
      run: approveOffer,
    } = useOliveAPI<OFFER_STATE>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${offerId}/offer_state`,
      data: { offerState: OFFER_STATE.ACTIVE },
      errorMessage: 'Unable to approve offer',
      successMessage: 'Offer was approved',
    })
    isLoading.value = true
    error.value = getOliveError()

    await approveOffer()

    if (response.value && !offerError.value.hasError) {
      approvedOfferState.value = response.value
      const offer = getOfferById.value(offerId)
      if (offer)
        offer.offerState = response.value
    }
    else { error.value = offerError.value }

    isLoading.value = false
  }

  const addOffer = async (offer: IOffer) => {
    const {
      response,
      error: offerError,
      run: addOffer,
    } = useOliveAPI<IOffer>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: offer,
      successMessage: 'Offer created successfully',
      errorMessage: 'Failed to create an offer',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addOffer()

    if (response.value && !offerError.value.hasError) {
      offers.value = useArrayUnique([response.value, ...offers.value], (a, b) => a.id === b.id).value
      selectedOfferId.value = response.value.id
    }
    else { error.value = offerError.value }

    isLoading.value = false
  }

  const updateOffer = async (offer: IOffer, showMessage = true) => {
    selectedOfferId.value = offer.id

    const {
      response,
      error: offerError,
      run: updateOffer,
    } = useOliveAPI<IOffer>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${offer.id}`,
      data: offer,
      successMessage: showMessage ? 'Offer updated successfully' : undefined,
      errorMessage: 'Failed to update offer',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateOffer()

    if (response.value && !offerError.value.hasError) {
      offers.value = useArrayUnique([response.value, ...offers.value], (a, b) => a.id === b.id).value
      updatedOffer.value = response.value
    }
    else { error.value = offerError.value }

    isLoading.value = false
  }

  const deleteOffer = async (offerId: string) => {
    const {
      error: offerError,
      run: deleteOffer,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${offerId}`,
      successMessage: 'Offer archived successfully',
      errorMessage: 'Failed to archive offer',
    })
    isLoading.value = true
    error.value = getOliveError()

    await deleteOffer()

    if (!offerError.value.hasError) {
      const offer = getOfferById.value(offerId)
      if (offer)
        offer.offerState = OFFER_STATE.ARCHIVED
    }
    else { error.value = offerError.value }

    isLoading.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    offerIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearSelectedOffer = () => {
    selectedOfferId.value = undefined
  }
  // #endregion

  return {
    offers,
    getOfferById,
    getOffersByIds,
    getOffersCurrentPage,
    getOffersWithoutGeolocationCurrentPage,
    getOfferMarkers,
    getSelectedOffer,
    clearSelectedOffer,
    isLoading,
    filter,
    numberOfOffers,
    approvedOfferState,
    updatedOffer,
    error,
    deleteOffer,
    loadOffers,
    loadOffersByIds,
    loadOffer,
    addOffer,
    updateOffer,
    approveOffer,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useOffersStore as any, import.meta.hot))
