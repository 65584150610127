import { acceptHMRUpdate, defineStore } from 'pinia'
import { CONTEXT } from '~/types/navigation.types'

export const useContextStore = defineStore('context', () => {
  const selectedClientId = useStorage('selectedClientId', null as string | null, sessionStorage)
  const selectedCorporateId = useStorage('selectedCorporateId', null as string | null, sessionStorage)
  const isDark = useStorage('isDark', false as boolean, sessionStorage)

  const viewContext = computed((): CONTEXT => {
    if (selectedClientId.value === useClientsStore().adminClient.id)
      return CONTEXT.NONE
    if (selectedCorporateId.value)
      return CONTEXT.CORPORATE
    else if (selectedClientId.value)
      return CONTEXT.CLIENT
    return CONTEXT.NONE
  })

  const setClientId = (clientId: string) => {
    if (selectedClientId.value !== clientId)
      selectedClientId.value = clientId
    selectedCorporateId.value = undefined
  }

  const setCorporateId = (corporateId: string) => {
    selectedClientId.value = undefined
    selectedCorporateId.value = corporateId
  }

  const selectedClient = computed(() => {
    if (selectedClientId.value)
      return useClientsStore().getClientById(selectedClientId.value)
  })

  const selectedCorporate = computed(() => {
    if (selectedCorporateId.value)
      return useCorporatesStore().getCorporateById(selectedCorporateId.value)
  })

  const allClientsAccess = computed(() => {
    return selectedClientId.value === useClientsStore().adminClient.id
  })

  const setIsDark = (dark: boolean) => {
    isDark.value = dark
  }

  return {
    viewContext,
    allClientsAccess,
    setClientId,
    setCorporateId,
    selectedClientId,
    selectedCorporateId,
    selectedClient,
    selectedCorporate,
    isDark,
    setIsDark,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useContextStore as any, import.meta.hot))
