import { acceptHMRUpdate, defineStore } from 'pinia'
import type { IClientCardTransaction, IClientCardTransactionPostParams, IClientCardTransactionRequestParams } from '~/types/clientCardTransaction.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'

export const useClientCardTransactionsStore = defineStore('clientCardTransactions', () => {
  const oliveBaseUrl = '/client_card_transactions'

  // #region state
  const clientCardTransactions = ref<IClientCardTransaction[]>([])
  const clientCardTransactionIdsCurrentPage = ref<string[]>([])
  const numberOfClientCardTransactions = ref<number | undefined>()
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IClientCardTransactionRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getClientCardTransactionById = computed(() => {
    return (id: string) => clientCardTransactions.value.find(c => c.id === id)
  })

  const getClientCardTransactionsCurrentPage = computed (() => {
    return clientCardTransactions.value.filter(c => clientCardTransactionIdsCurrentPage.value.includes(c.id))
  })
  // #endregion

  // #region actions
  const loadClientCardTransactions = async (params?: IClientCardTransactionRequestParams) => {
    const {
      response,
      error: clientCardTransactionError,
      run: loadClientCardTransactions,
    } = useOliveAPI<IPlatformResponse<IClientCardTransaction>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading client card transactions',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadClientCardTransactions()

    if (response.value?.items && !clientCardTransactionError.value.hasError) {
      clientCardTransactions.value = useArrayUnique([...response.value.items, ...clientCardTransactions.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        clientCardTransactionIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfClientCardTransactions.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = clientCardTransactionError.value }

    isLoading.value = false
  }

  const loadClientCardTransaction = async (id: string) => {
    if (getClientCardTransactionById.value(id))
      return

    const {
      response,
      error: clientCardTransactionError,
      run: loadClientCardTransaction,
    } = useOliveAPI<IClientCardTransaction>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading client card transaction',
    })
    isLoading.value = true
    error.value = clientCardTransactionError.value

    await loadClientCardTransaction()

    if (response.value && !clientCardTransactionError.value.hasError)
      clientCardTransactions.value = useArrayUnique([response.value, ...clientCardTransactions.value], (a, b) => a.id === b.id).value
    else
      error.value = clientCardTransactionError.value

    isLoading.value = false
  }

  const postClientCardTransaction = async (postModel: IClientCardTransactionPostParams) => {
    const {
      error: clientCardTransactionError,
      run: postClientCardTransaction,
    } = useOliveAPI<IClientCardTransactionPostParams>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: postModel,
      successMessage: `${postModel.transactionType ? `${oliveEnumToReadableStringMapper(postModel.transactionType)} transaction created` : 'Client card transaction created '}`,
      errorMessage: 'Error creating client card transaction',
    })
    isLoading.value = true
    error.value = clientCardTransactionError.value

    await postClientCardTransaction()

    isLoading.value = false
  }

  const clearCurrentPage = () => {
    clientCardTransactionIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }
  // #endregion

  return {
    clientCardTransactions,
    numberOfClientCardTransactions,
    getClientCardTransactionById,
    getClientCardTransactionsCurrentPage,
    isLoading,
    filter,
    error,
    loadClientCardTransactions,
    loadClientCardTransaction,
    postClientCardTransaction,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useClientCardTransactionsStore as any, import.meta.hot))
