import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import type { IWorkItem, IWorkItemRequestParams, IWorkItemsCount, IWorkItemsCountsParams, WORK_ITEM_TYPE } from '~/types/workItem.types'
import { WORK_ITEM_STATUS } from '~/types/workItem.types'

export const useWorkItemsStore = defineStore('workItems', () => {
  const oliveBaseUrl = '/work_items'

  // #region state
  const workItems = ref<IWorkItem[]>([])
  const selectedWorkItemId = ref<string>()
  const workItemIdsCurrentPage = ref<string[]>([])
  const numberOfWorkItems = ref<number>(0)

  const workItemsCount = ref<IWorkItemsCount[]>([])
  const newWorkItemsCount = ref<string | number>()

  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IWorkItemRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getWorkItemById = computed(() => {
    return (id: string) => workItems.value.find(b => b.id === id)
  })

  const getWorkItemsCurrentPage = computed (() => {
    return workItems.value.filter(b => workItemIdsCurrentPage.value.includes(b.id))
  })

  const getWorkItemsCounts = computed(() => {
    return (status: WORK_ITEM_STATUS) => workItemsCount.value.filter(b => b.status === status)
  })

  function setNewWorkItemsCounts(increment = 0, status?: WORK_ITEM_STATUS, type?: WORK_ITEM_TYPE) {
    if (status && type) {
      const index = workItemsCount.value.findIndex(item => item.status === status && item.type === type)
      if (index !== -1) {
        workItemsCount.value[index].count += increment
        if (workItemsCount.value[index].count === 0)
          workItemsCount.value.splice(index, 1)
      }
      else {
        workItemsCount.value.push({ count: increment, status, type })
      }
    }

    const totalCreatedWorkItems = getWorkItemsCounts.value(WORK_ITEM_STATUS.CREATED)
    newWorkItemsCount.value = totalCreatedWorkItems ? totalCreatedWorkItems.reduce((total, item) => total + item.count, 0) : 0
    if (newWorkItemsCount.value as number > 999)
      newWorkItemsCount.value = '999+'
  }

  const getSelectedWorkItem = computed(() => {
    if (selectedWorkItemId.value)
      return getWorkItemById.value(selectedWorkItemId.value)
  })
  // #endregion

  // #region actions
  const loadWorkItems = async (params?: IWorkItemRequestParams, signal?: AbortSignal) => {
    const {
      response,
      error: workItemError,
      run: loadWorkItems,
    } = useOliveAPI<IPlatformResponse<IWorkItem>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading work items',
    }, signal)
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadWorkItems()

    if (response.value?.items && !workItemError.value.hasError) {
      workItems.value = useArrayUnique([...response.value.items, ...workItems.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        workItemIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfWorkItems.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = workItemError.value }

    isLoading.value = false
  }

  const loadWorkItemsCounts = async (params?: IWorkItemsCountsParams, signal?: AbortSignal) => {
    const {
      response,
      error: workItemError,
      run: loadWorkItemsCount,
    } = useOliveAPI<IWorkItemsCount[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/counts`, params),
      errorMessage: 'Error loading work items counts',
    }, signal)
    isLoading.value = true
    error.value = getOliveError()

    await loadWorkItemsCount()

    if (response.value && !workItemError.value.hasError)
      workItemsCount.value = useArrayUnique([...response.value, ...workItemsCount.value], (a, b) => (a.status + a.type) === (b.status + b.type)).value
    else
      error.value = workItemError.value

    setNewWorkItemsCounts()
    isLoading.value = false
  }

  const loadWorkItem = async (id: string) => {
    selectedWorkItemId.value = id

    if (getWorkItemById.value(id))
      return

    const {
      response,
      error: workItemError,
      run: loadWorkItem,
    } = useOliveAPI<IWorkItem>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading work item',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadWorkItem()

    if (response.value && !workItemError.value.hasError)
      workItems.value = useArrayUnique([response.value, ...workItems.value], (a, b) => a.id === b.id).value
    else error.value = workItemError.value

    isLoading.value = false
  }

  const addWorkItem = async (item: IWorkItem) => {
    const {
      response,
      error: workItemError,
      run: addWorkItem,
    } = useOliveAPI<IWorkItem>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: item,
      errorMessage: 'Failed to create a work item',
      successMessage: 'Work item created',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addWorkItem()

    if (response.value && !workItemError.value.hasError) {
      workItems.value = useArrayUnique([response.value, ...workItems.value], (a, b) => a.id === b.id).value
      selectedWorkItemId.value = response.value.id
      setNewWorkItemsCounts(1, item.status, item.type)
    }
    else {
      error.value = workItemError.value
    }

    isLoading.value = false
  }

  const updateWorkItem = async (item: IWorkItem) => {
    selectedWorkItemId.value = item.id

    const {
      response,
      error: workItemError,
      run: updateWorkItem,
    } = useOliveAPI<IWorkItem>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${item.id}`,
      data: item,
      errorMessage: 'Failed to update work item',
      successMessage: 'Work item updated',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateWorkItem()

    if (response.value && !workItemError.value.hasError) {
      const workItem = getWorkItemById.value(item.id)
      if (workItem)
        setNewWorkItemsCounts(-1, workItem.status, workItem.type)
      setNewWorkItemsCounts(1, response.value.status, response.value.type)
      workItems.value = useArrayUnique([response.value, ...workItems.value], (a, b) => a.id === b.id).value
    }
    else {
      error.value = workItemError.value
    }

    isLoading.value = false
  }

  const deleteWorkItem = async (itemId: string) => {
    const {
      error: workItemError,
      run: deleteWorkItem,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${itemId}`,
      successMessage: 'Work item removed',
      errorMessage: 'Failed to remove work item',
    })
    isLoading.value = true
    error.value = getOliveError()

    await deleteWorkItem()

    if (!workItemError.value.hasError) {
      const workItem = getWorkItemById.value(itemId)
      if (workItem)
        setNewWorkItemsCounts(-1, workItem.status, workItem.type)
      workItems.value = workItems.value.filter(f => f.id !== itemId)
    }
    else {
      error.value = workItemError.value
    }

    isLoading.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    workItemIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearSelectedWorkItem = () => {
    selectedWorkItemId.value = undefined
  }
  // #endregion

  return {
    workItems,
    workItemsCount,
    numberOfWorkItems,
    getSelectedWorkItem,
    clearSelectedWorkItem,
    newWorkItemsCount,
    isLoading,
    getWorkItemById,
    getWorkItemsCurrentPage,
    getWorkItemsCounts,
    filter,
    error,
    loadWorkItems,
    loadWorkItem,
    addWorkItem,
    updateWorkItem,
    deleteWorkItem,
    loadWorkItemsCounts,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useWorkItemsStore as any, import.meta.hot))
