// #region const
export const SNACKBAR_TIMER = 500
// #endregion

// #region enums
export enum NOTIFICATION_TYPE {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum NOTIFICATION_ICON_TYPE {
  SUCCESS = 'mdi-check-circle',
  WARNING = 'mdi-alert',
  ERROR = 'mdi-alert-circle',
  INFO = 'mdi-information',
}
// #endregion

// #region interfaces
export interface INotification {
  background?: string
  title?: string
  text?: string
  icon?: NOTIFICATION_ICON_TYPE
  timeout?: number
  type?: NOTIFICATION_TYPE
  visible?: boolean
}
// #endregion
