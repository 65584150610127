<script setup lang="ts">
import { useDark, useToggle } from '@vueuse/core'
import { AuthenticationProperties as auth0 } from 'vue-auth0-plugin'
import { useTheme } from 'vuetify/lib/framework.mjs'
import webConfig from '~/config'
import { THEME } from '~/types/common.types'
import { CONTEXT } from '~/types/navigation.types'
import type { IAuthUser } from '~/types/user.types'

const version = webConfig.app.version
const nav = useNavigationStore()
const userStore = useUserStore()
const theme = useTheme()
const contextStore = useContextStore()
const contextEnum = CONTEXT

const isDark = useDark()
const toggleDark = useToggle(isDark)
contextStore.setIsDark(isDark.value)

function handleLogout() {
  auth0.logout({
    logoutParams: {
      returnTo: webConfig.auth.logout_uri,
    },
  })
}
const isUserUploadedImage = !userStore.me?.picture?.includes('cdn.auth0.com')

const avatarInitials = ref('')
const firstName = ref((userStore.me && userStore.me.firstName) ? userStore.me.firstName : '')
const lastName = ref((userStore.me && userStore.me.lastName) ? userStore.me.lastName : '')
const email = ref((userStore.me && userStore.me.email) ? userStore.me.email : '')

function setAvatarInitials(firstName: string | undefined, lastName: string | undefined, email: string | undefined) {
  avatarInitials.value = ((firstName ? firstName[0] : (email ? email[0] : ''))
    + (lastName ? lastName[0] : (email ? email[1] : ''))).toLocaleUpperCase()
}

setAvatarInitials(userStore.me?.firstName, userStore.me?.lastName, userStore.me?.email)
firstName.value = userStore.me?.firstName || ''
lastName.value = userStore.me?.lastName || ''
email.value = userStore.me?.email || ''

function updateInfo(updatedUserInfo: IAuthUser) {
  setAvatarInitials(updatedUserInfo.firstName, updatedUserInfo.lastName, updatedUserInfo.email)
  firstName.value = updatedUserInfo.firstName || ''
  lastName.value = updatedUserInfo.lastName || ''
  email.value = updatedUserInfo.email
}

const themeSwitcherIsDark = ref(isDark)
theme.global.name.value = isDark.value ? THEME.DARK : THEME.LIGHT

watch(() => (useUserStore().me), (me) => {
  if (me)
    updateInfo(me)
})

function themeSwitch() {
  if (isDark.value)
    toggleDark(false)
  else
    toggleDark(true)

  contextStore.setIsDark(isDark.value)
  theme.global.name.value = isDark.value ? THEME.DARK : THEME.LIGHT
}

watch(themeSwitcherIsDark, (themeDark) => {
  if (themeDark)
    toggleDark(true)
  else
    toggleDark(false)

  contextStore.setIsDark(isDark.value)
  theme.global.name.value = isDark.value ? THEME.DARK : THEME.LIGHT
})

const clientSelectReloadKey = ref(0)
watch(() => contextStore.selectedClient, (newClient, oldClient) => {
  if ((!oldClient) || (!newClient) || (newClient.id !== oldClient.id))
    clientSelectReloadKey.value++
})

watch(() => contextStore.selectedCorporate, (newCorp, oldCorp) => {
  if ((!oldCorp) || (!newCorp) || (newCorp.id !== oldCorp.id))
    clientSelectReloadKey.value++
})
</script>

<template>
  <v-app-bar
    elevation="2"
    scroll-behavior="elevate"
    density="comfortable"
  >
    <v-app-bar-nav-icon
      v-if="!nav.navBar.isHidden"
      @click.stop="nav.navBar.toggleNav()"
    />
    <ClientSelect :key="clientSelectReloadKey" />
    <v-app-bar-title class="d-none d-sm-flex justify-left">
      <template v-if="contextStore.viewContext === contextEnum.CLIENT">
        {{ contextStore.selectedClient?.fullName }}
      </template>
      <template v-else-if="contextStore.viewContext === contextEnum.CORPORATE">
        {{ contextStore.selectedCorporate?.companyName }}
      </template>
      <template v-else>
        Customer Portal
      </template>
    </v-app-bar-title>
    <template #append>
      <span class="pr-2">
        <v-btn
          :icon="isDark ? 'mdi-weather-night' : 'mdi-weather-sunny'"
          variant="plain"
          @click="themeSwitch"
        />
      </span>
      <v-menu :close-on-content-click="false">
        <template #activator="{ props }">
          <v-btn
            icon
            v-bind="props"
          >
            <v-avatar
              v-if="isUserUploadedImage"
              class="profile-picture"
              color="primary"
            >
              <v-img :src="userStore?.me?.picture" />
            </v-avatar>
            <v-avatar
              v-if="!isUserUploadedImage"
              class="profile-picture"
              color="primary"
            >
              <span style="color: white;">{{ avatarInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list
          class="mt-1"
          density="compact"
        >
          <v-list-item class="mb-2">
            <template #prepend>
              <v-avatar
                v-if="isUserUploadedImage"
                class="profile-picture"
              >
                <v-img :src="userStore?.me?.picture" />
              </v-avatar>
              <v-avatar
                v-if="!isUserUploadedImage"
                class="profile-picture"
                color="primary"
              >
                <span style="color: white;">{{ avatarInitials }}</span>
              </v-avatar>
            </template>
            <v-list-item-title>{{ `${firstName} ${lastName}` }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="my-2" />
          <v-list-item
            class="list-item-title-small"
            link
            :to="{ name: 'profile' }"
            value="Profile"
          >
            <v-list-item-title>Manage Profile</v-list-item-title>
          </v-list-item>
          <v-list-item
            class="list-item-title-small"
            link
            href="https://support.oliveltd.com/"
            value="Request Support"
            target="_blank"
          >
            <template #append>
              <v-icon
                icon="mdi-open-in-new"
                size="x-small"
              />
            </template>
            <v-list-item-title>
              Request Support
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item
            class="list-item-title-small"
            link
            value="Logout"
            @click="handleLogout"
          >
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
          <v-divider v-if="version" />
          <code
            v-if="version"
            class="version"
            disabled
          >v.{{ version }}</code>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>
