import type { AUTOCOMPLETE_TYPE, OliveRequestParams } from './common.types'
import type { ICorporateSupportedOfferMarketingFeeType, OFFER_MARKETING_FEE_TYPE } from './marketing.types'
import type { OFFER_MERCHANT_SUPPLIER_TYPE } from './offerMerchantSupplierType.types'
import type { ICorporateOptions, IPaginationOptions, ISearchOptions } from './platform.types'

// #region consts {
export const CORPORATE_AUTO_APPROVAL_DAYS_DEFAULT = 30
export const CORPORATE_AUTO_RECHARGE_AMOUNT_MAXIMUM = 10000
// #endregion

// #region enums
export enum IN_USE_BY_CORPORATE {
  ALL = 'all',
}
// endregion

// #region interfaces
export interface ICorporate {
  id: string
  companyName: string
  companyEmail: string
  created?: string
  availability: string
  phone?: string
  address1: string
  address2?: string
  city: string
  countryCode: string
  postCode: string
  state: string
  companyWebsite?: string
  clientId: string
  type?: AUTOCOMPLETE_TYPE
  offerRedemptionAutoApproveDays?: number
  supplierType?: OFFER_MERCHANT_SUPPLIER_TYPE
  currencyCode: string
  defaultOfferMarketingFeeType: OFFER_MARKETING_FEE_TYPE
  supportedOfferMarketingFeeTypes: ICorporateSupportedOfferMarketingFeeType[]
  accountAutoRechargeAmount?: number
  accountAutoRechargeThresholdAmount?: number
  accountLiabilityAmount?: number
  accountAutoRechargePaymentMethodId?: string
}

export interface ICorporateDetails {
  clientId: string
  companyName?: string
  companyEmail?: string
  phone?: string
  address1?: string
  address2?: string
  city?: string
  countryCode?: string
  postCode?: string
  state?: string
  companyWebsite?: string
  supplierType?: OFFER_MERCHANT_SUPPLIER_TYPE
  currencyCode?: string
}

export interface ICorporateRequestParams extends OliveRequestParams, IPaginationOptions, ISearchOptions, ICorporateOptions {}

export interface ICorporateAutoRecharge {
  clientId: string
  corporateId: string
  currencyCode: string
  accountAutoRechargeAmount?: number
  accountAutoRechargeThresholdAmount?: number
  accountLiabilityAmount?: number
  accountAutoRechargePaymentMethodId?: string
}

export interface ICorporateOfferRedemptionsAutoApproval {
  corporateId: string
  autoApproveNumberOfDays?: number
}

export interface ICorporateDefaultMarketingFees {
  corporateId: string
  currencyCode: string
  defaultOfferMarketingFeeType: OFFER_MARKETING_FEE_TYPE
  supportedOfferMarketingFeeTypes: ICorporateSupportedOfferMarketingFeeType[]
}
// #endregion
