import type { App } from 'vue'
import type { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'

import webConfig from '~/config'

export function initSentry(app: App<Element>, router: Router) {
  const sentryIntegrations = [
    Sentry.browserTracingIntegration({ router }),
    Sentry.httpClientIntegration(),
    Sentry.sessionTimingIntegration(),
  ]

  if (webConfig.app.envName === 'develop') {
    sentryIntegrations.push(Sentry.replayIntegration())
  }

  Sentry.init({
    app,
    dsn: 'https://9b75d0a97b23b5de3f9868c9fcaef0fa@o4508087536582656.ingest.us.sentry.io/4508128527384576',
    maxBreadcrumbs: 50,
    // debug: !webConfig.app.production,
    environment: webConfig.app.envName === 'develop' ? 'development' : webConfig.app.envName, // for consistency
    release: webConfig.app.version,
    trackComponents: true,

    // This enables automatic instrumentation (highly recommended),
    // but is not necessary for purely manual usage
    // If you only want to use custom instrumentation:
    // * Remove the `BrowserTracing` integration
    // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
    integrations: sentryIntegrations,

    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: webConfig.app.envName === 'production' ? 0.6 : 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api(?:\.(?:dev|sandbox))?\.oliveltd\.com\//,
    ],
    // Session Replay
    replaysSessionSampleRate: webConfig.app.envName === 'production' ? 0.6 : 0.2, // 60% in prod, 20% in sandbox (off in dev)
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
