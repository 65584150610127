import webConfig from '~/config'

const ga = {
  appName: 'Customer Portal',
  disableScriptLoad: webConfig.app.envName === 'local',
  pageTrackerScreenviewEnabled: true,
  config: {
    id: webConfig.app.gaMeasurementId,
  },
}

export default ga
