import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios'
import type {
  AxiosCacheInstance,
  InternalCacheRequestConfig,
  MemoryStorage,
} from 'axios-cache-interceptor'
import axios from 'axios'
import {
  buildMemoryStorage,
  defaultHeaderInterpreter,
  defaultKeyGenerator,
  setupCache,
} from 'axios-cache-interceptor'
import qs from 'qs'
import { AuthenticationProperties as auth0 } from 'vue-auth0-plugin'

const TTL = 1000 * 60 * 30
function isValidHttpStatus(status: number): boolean {
  return status >= 200 && status < 400
}

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

interface Auth0Error {
  error: string
}

function createApiInstance(baseURL: string, headers: RawAxiosRequestHeaders | undefined, overrides: AxiosRequestConfig = {}): AxiosInstance {
  return axios.create({
    baseURL,
    headers,
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    },
    ...overrides,
  })
}

abstract class HttpClient {
  protected readonly api: AxiosCacheInstance
  private cache: MemoryStorage

  public constructor(baseURL: string, headers?: RawAxiosRequestHeaders, overrides?: AxiosRequestConfig) {
    this.cache = buildMemoryStorage()
    this.api = setupCache(createApiInstance(baseURL, headers, overrides), {
      storage: this.cache,
      ttl: TTL,
      generateKey: defaultKeyGenerator,
      headerInterpreter: defaultHeaderInterpreter,
      methods: ['get'],
      cachePredicate: {
        statusCheck: status => isValidHttpStatus(status),
      },
      staleIfError: false,
    })
    this._initializeResponseInterceptor()
  }

  public invalidateCache = () => {
    this.cache.data = Object.create(null)
  }

  private _initializeResponseInterceptor = () => {
    this.api.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    )
    this.api.interceptors.request.use(
      this._handleRequest,
      this._handleError,
    )
  }

  removeAuthToken = (): void => {
    this.api.defaults.headers.common.Authorization = ''
    delete this.api.defaults.headers.common.Authorization
  }

  setAuthToken = (token: string): void => {
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  setHeaders = (headers: RawAxiosRequestHeaders): void => {
    this.api.defaults.headers.common = headers
  }

  private _handleRequest = async (config: InternalCacheRequestConfig): Promise<InternalCacheRequestConfig> => {
    if (config.method !== 'get')
      this.invalidateCache()

    try {
      const token = await auth0.getTokenSilently()
      if (config.headers)
        config.headers.Authorization = `Bearer ${token}`
    }
    catch (err) {
      const error = err as Auth0Error
      if (error.error === 'login_required' || error.error === 'missing_refresh_token')
        auth0.loginWithRedirect()
    }

    return config
  }

  private _handleResponse = ({ data }: AxiosResponse) => data

  protected _handleError = (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      const response = error.response
      const data: any = response.data
      const errorMessage = data.messages ? data.messages[0] : error.message

      throw new OliveError(errorMessage, data.errorCode)
    }
    else if (error.code === 'ERR_CANCELED') {
      // eslint-disable-next-line no-console
      console.info('Request was canceled')
    }
    else if (error.request) {
      // The request was made but no response was received
      throw new OliveError(`Error request: ${error.request}`, -1)
    }
    else {
      // Something happened in setting up the request that triggered an Error
      throw new OliveError(`Error: ${error.message}`, -1)
    }
  }
}

const singleton = HttpClient
export default singleton

export { createApiInstance }
