import { differenceInDays, differenceInMonths, differenceInWeeks, differenceInYears } from 'date-fns'
import { INTERVAL_TYPE } from '~/types/platform.types'

// prefixed wildcard must have at least 3 chars when searching in auth0
export const userSearchRules = /^\*[a-z0-9]{1,2}$/i
export const guidRules = /^(\{)?[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(\})?$/i
export const validImageExtensionRegex = /\S.*\.(jpg|jpeg|png|gif|svg|webp)$/i
export const validImageUrlRegex = /^[\w\-.~:/?#[\]@!$&'()*+,;=%]+\.(jpg|jpeg|png|svg|webp|gif)(?:\?.*)?$/i
export const validUrlRegex = /^https?:\/\/([\w\-]+\.)+[a-z]{2,63}(:\d{1,5})?(\/[^\s#?]*)?(\?[^#]*)?(#.*)?$/i
export const validImageTypeBlobRegex = /^image\/(jpeg|jpg|png|gif|webp|svg\+xml)$/i
export const brandLogoSizeRestriction = 1000000
export const validFontUrlRegex = /^(https?:\/\/[\w\-.~:/?#[\]@!$&'()*+,;=%]+\.(woff|woff2)(?:\?.*)?)$/i

export function isJSONWellFormatted(jsonString: string): boolean {
  try {
    JSON.parse(jsonString)
    return true
  }
  catch (error) {
    return false
  }
}

export function requiredRules(label = '', required = true) {
  return [
    (v: any) => !required || !!v || `${label} is required`,
  ]
}

export function requiredRulesMulti(label = '', required = true) {
  return [
    (v: any) => !required || (Array.isArray(v) && v.length > 0) || `${label} is required`,
  ]
}

export function nameRules(label = 'Name', limit = -1) {
  return [
    (v: any) => !!v || `${label} is required`,
    (v: string | any[]) => (limit === -1) || (v && v.length <= limit) || `${label} must be less than or equal to ${limit} characters`,
  ]
}

export function emailRules(required = true) {
  return [
    (v: any) => !!v || !required || 'Email is required',
    (v: string) => !v || /.[^\n\r@\u2028\u2029]*@.+\..+/.test(v) || 'Email must be valid',
  ]
}

export function positiveWholeNumberRules(label = '', minimum = 0, symbol = '') {
  return [
    (v: any) => /^[1-9]\d*$/.test(v) || `${label} must be a positive integer`,
    (v: any) => v >= minimum || `${label} must be greater than or equal to ${symbol}${minimum}`,
  ]
}

export function positiveOrZeroDecimalNumberRules(label = '', minimum = 0, symbol = '', maximum?: number) {
  return [
    (v: any) => v >= 0 || `${label} must be a positive number`,
    (v: any) => v >= minimum || `${label} must be greater than or equal to ${symbol}${minimum}`,
    (v: any) => maximum === undefined || v <= maximum || `${label} must be less than or equal to ${symbol}${maximum}`,
  ]
}

export function positiveOrZeroGreaterThanMinDecimalNumberRules(label = '', minimum = 0, symbol = '', maximum?: number) {
  return [
    (v: any) => v >= 0 || `${label} must be a positive number`,
    (v: any) => v > minimum || `${label} must be greater than ${symbol}${minimum}`,
    (v: any) => maximum === undefined || v <= maximum || `${label} must be less than or equal to ${symbol}${maximum}`,
  ]
}

export function positiveOrZeroGreaterThanMinLessThanMaxDecimalNumberRules(label = '', minimum = 0, symbol = '', maximum?: number) {
  return [
    (v: any) => v >= 0 || `${label} must be a positive number`,
    (v: any) => v > minimum || `${label} must be greater than ${symbol}${minimum}`,
    (v: any) => maximum === undefined || v < maximum || `${label} must be less than ${symbol}${maximum}`,
  ]
}

export function positiveDecimalNumberRules(label = '', minimum = 0, symbol = '') {
  return [
    (v: any) => v > 0 || `${label} must be a positive number`,
    (v: any) => v >= minimum || `${label} must be greater than or equal to ${symbol}${minimum}`,
  ]
}

export function positiveWholeNumberRulesOrEmpty(label = '', minimum = 0, symbol = '') {
  return [
    (v: any) => v === undefined || v === '' || /^[1-9]\d*$/.test(v) || `${label} must be a positive integer or empty`,
    (v: any) => v === undefined || v >= minimum || `${label} must be greater than or equal to ${symbol}${minimum}`,
  ]
}

export function perMemberRedeemLimitRules(label = '', minimum = 0, required = false, max = 1000) {
  return [
    (v: any) => !required || !!v || `${label} is required when Redemption Window is enabled`,
    (v: any) => v === undefined || v === '' || /^[1-9]\d*$/.test(v) || `${label} must be a positive integer`,
    (v: number) => !required || (v >= minimum && v <= Number(max)) || `${label} must be between ${minimum} and ${max}`,
  ]
}

export function positiveWholeNumberOrZeroRulesOrEmpty(label = '', minimum = -1, symbol = '') {
  return [
    (v: any) => v === undefined || v === '' || /^\d+$/.test(v) || `${label} must be a positive integer`,
    (v: any) => v === undefined || v >= minimum || `${label} must be greater than or equal to ${symbol}${minimum}`,
  ]
}

export function positiveDecimalNumberRulesOrEmpty(label = '', minimum = 0, symbol = '') {
  return [
    (v: any) => v === undefined || v > 0 || /^$/.test(v) || `${label} must be a positive number or empty`,
    (v: any) => v === undefined || v >= minimum || `${label} must be greater than or equal to ${symbol}${minimum}`,
  ]
}

export function budgetRulesOrEmpty(label = '', canBeEmpty: boolean) {
  return [
    (v: any) => !!canBeEmpty || !!v || `${label} is required when Redemption Window is enabled`,
  ]
}

export function increaseOnlyRule(label = '', currentValue = 1, canBeEmpty = false, max?: number) {
  return [
    (v: any) => (canBeEmpty && (v === undefined || v === '')) || v >= currentValue || `${label} can only be increased`,
    (v: any) => (canBeEmpty && (v === undefined || v === '')) || /^(?:[1-9]\d*|0)$/.test(v) || `${label} must be a positive integer or empty`,
    (v: any) => !max || (canBeEmpty && (v === undefined || v === '')) || (max !== undefined && v <= Number(max)) || `${label} must be less than or equal to ${max}`,
  ]
}

export function postalCodeRules(required = true) {
  return [
    (v: any) => !!v || !required || 'Postal Code is required',
    (v: string) => !v || /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?\d[A-Z]\d$/.test(v) || 'Postal Code must be valid (A1A2B2)',
  ]
}

export function zipCodeRules(required = true) {
  return [
    (v: any) => !!v || !required || 'Zip Code is required',
    (v: string) => !v || /^\d{5}(?:-\d{4})?$/.test(v) || 'Zip Code must be valid',
  ]
}

export function logoRules(required = false, size = '') {
  return [
    (v: any) => !required || (Array.isArray(v) && v.length > 0) || `${size} is required`,
    (v: any) => !v || !v.length || v[0].size < brandLogoSizeRestriction || `${size} must be less than 1 MB`,
  ]
}

export function latitudeRules() {
  return [
    (v: string) => !v || /^(?:\+|-)?(?:90(?:\.0{1,6})?|(?:\d|[1-8]\d)(?:\.\d{1,14})?)$/.test(v) || 'Latitude must be valid',
  ]
}

export function longitudeRules() {
  return [
    (v: string) => !v || /^(?:\+|-)?(?:180(?:\.0{1,6})?|(?:\d|[1-9]\d|1[0-7]\d)(?:\.\d{1,14})?)$/.test(v) || 'Longitude must be valid',
  ]
}

export function urlRules(label = '', required = true) {
  return [
    (v: any) => (!required && !v) || validUrlRegex.test(v) || `${label} must be a valid URL`,
  ]
}

export function fontUrlRules(label = '') {
  return [
    (v: any) => !v || v === '' || validFontUrlRegex.test(v) || `${label} must be a valid URL pointing directly to a woff or woff2 font file`,
  ]
}

export function guidRule() {
  return [
    (v: string) => !v || guidRules.test(v) || 'Guid must be valid',
  ]
}

export function redeemLimitPerIntervalRules(label = '', interval: INTERVAL_TYPE | undefined, required = false, validate = true, min = 1, numOfYears = 3) {
  if (interval) {
    let max: number
    switch (interval) {
      case INTERVAL_TYPE.DAY:
        max = numOfYears * 365
        break
      case INTERVAL_TYPE.WEEK:
        max = numOfYears * 52
        break
      case INTERVAL_TYPE.MONTH:
        max = numOfYears * 12
        break
      case INTERVAL_TYPE.YEAR:
        max = numOfYears
        break
      default:
        max = 365
        break
    }
    return [
      (v: number) => !required || !validate || (v >= min && v <= max) || `${label} must be between ${min} and ${max}`,
    ]
  }
}

export function redeemLimitIntervalRules(label = '', startDate: string, endDate: string, required = false, validate = true) {
  const days = differenceInDays(new Date(endDate), new Date(startDate))
  const weeks = differenceInWeeks(new Date(endDate), new Date(startDate))
  const months = differenceInMonths(new Date(endDate), new Date(startDate))
  const years = differenceInYears(new Date(endDate), new Date(startDate))
  return [
    (v: string) => !required || !!v || `${label} is required`,
    (v: string) => !v || !validate || (v.toLowerCase() === INTERVAL_TYPE.DAY && (!Number.isNaN(days) && days > 0))
      || (v.toLowerCase() === INTERVAL_TYPE.WEEK && (!Number.isNaN(weeks) && weeks > 0))
      || (v.toLowerCase() === INTERVAL_TYPE.MONTH && (!Number.isNaN(months) && months > 0))
      || (v.toLowerCase() === INTERVAL_TYPE.YEAR && (!Number.isNaN(years) && years > 0)) || `${label} is longer than the total offer lifespan`,
  ]
}

export function jsonRules(label = '', required = false) {
  return [
    (v: any) => !required || !!v || `${label} is required`,
    (v: string) => !v || isJSONWellFormatted(v) || `${label} must be a valid JSON`,
  ]
}

export function noRules() {
  return [
    () => true,
  ]
}
