import type { Ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { IAccountTransaction, IAccountTransactionRequestParams, IAddAccountTransaction, IPayOutAccountBalanceRequest, IPayOutBalanceResponse } from '~/types/account.types'
import { HTTP_METHOD } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'

export const useAccountTransactionsStore = defineStore('accountTransactions', () => {
  const oliveBaseUrl = '/accounts'

  // #region state
  const accountTransactions = ref<IAccountTransaction[]>([])
  const accountTransactionIdsCurrentPage = ref<string[]>([])
  const numberOfAccountTransactions = ref<number | undefined>()
  const error = ref<IOliveError>(getOliveError())
  const isLoadingAccountTransactions = ref(false)
  const isLoadingPayout = ref(false)

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getAccountTransactionById = computed(() => {
    return (id: string) => accountTransactions.value.find(a => a.id === id)
  })

  const getAccountTransactionsCurrentPage = computed(() => {
    return accountTransactions.value.filter(a => accountTransactionIdsCurrentPage.value.includes(a.id))
  })

  // #region actions
  const loadAccountTransactions = async (
    accountId: string,
    params?: IAccountTransactionRequestParams | Ref<IAccountTransactionRequestParams>,
  ) => {
    const {
      response,
      error: accountError,
      run: loadAccountTransactions,
    } = useOliveAPI<IPlatformResponse<IAccountTransaction>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/${accountId}/transactions`, params),
      errorMessage: 'Error loading account transactions',
    })
    isLoadingAccountTransactions.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadAccountTransactions()

    if (response.value && !accountError.value.hasError) {
      accountTransactions.value = useArrayUnique([...response.value.items, ...accountTransactions.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        accountTransactionIdsCurrentPage.value = [...response.value.items.map(a => a.id)]
        numberOfAccountTransactions.value = response.value?.totalNumberOfRecords
      }
    }
    else { error.value = accountError.value }

    isLoadingAccountTransactions.value = false
  }

  const postAccountTransaction = async (addAccountTransaction: IAddAccountTransaction) => {
    const {
      error: accountError,
      run: postAccountTransaction,
    } = useOliveAPI<IAddAccountTransaction>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${addAccountTransaction.accountId}/transactions`,
      data: addAccountTransaction,
      successMessage: 'Account Transaction Created',
      errorMessage: 'Error creating account transaction',
    })
    isLoadingAccountTransactions.value = true
    error.value = getOliveError()

    await postAccountTransaction()

    if (accountError.value.hasError)
      error.value = accountError.value

    isLoadingAccountTransactions.value = false
  }

  const postPayOutBalance = async (accountId: string, payoutBalanceRequest: IPayOutAccountBalanceRequest) => {
    const {
      response,
      error: accountError,
      run: postPayOutBalance,
    } = useOliveAPI<IPayOutBalanceResponse>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${accountId}/pay_out_balance`,
      data: payoutBalanceRequest,
      successMessage: 'Balance has been paid out',
      errorMessage: 'Error paying out balance',
    })
    isLoadingPayout.value = true
    error.value = getOliveError()

    await postPayOutBalance()

    if (accountError.value.hasError)
      error.value = accountError.value

    isLoadingPayout.value = false

    return response
  }

  const clearCurrentPage = () => {
    accountTransactionIdsCurrentPage.value = []
  }

  return {
    accountTransactions,
    numberOfAccountTransactions,
    error,
    isLoadingAccountTransactions,
    isLoadingPayout,
    getAccountTransactionById,
    getAccountTransactionsCurrentPage,
    postAccountTransaction,
    postPayOutBalance,
    loadAccountTransactions,
    clearCurrentPage,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAccountTransactionsStore as any, import.meta.hot))
